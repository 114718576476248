import React, { useEffect, useState } from "react";
import classes from "../../src/Style/ViewMessage.module.css";
import { LinearProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFetch } from "../hooks/useFetch";
import Wrapper from "../Components/Wrapper";

export default function ViewMessage() {
  const { Fetch } = useFetch();
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const [data, setData] = useState({});
  const [loading, setloading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setloading(true);
      let res = await Fetch("GET", `users/message/${params.get("id")}`);

      setData(res);
      setloading(false);
    };
    getData();
  }, []);

  return (
    <Wrapper bodyFullHeight>
      <section className={classes.BodySection}>
        <div className={classes.DataTableHeader}>
          <h2 className={classes.comptitile}>Message Details</h2>

          <div className={classes.DropdownFilters} />
        </div>

        <div className={classes.bodyContainer}>
          <div className={classes.messageDetailsContainer}>
            <div className={classes.messageRow}>
              <div className={classes.rowTitle}>Type</div>
              <div className={classes.rowBody}>
                {messageTypes[data.type] || "-----"}
              </div>
            </div>
            <div className={classes.messageRow}>
              <div className={classes.rowTitle}>Subject</div>
              <div className={classes.rowBody}>{data.subject || "-----"}</div>
            </div>
            <div className={classes.messageRow}>
              <div dir= "auto" className={classes.rowTitle}>Message</div>
              <div dir= "auto" className={classes.rowBody}>{data.message || "-----"}</div>
            </div>
          </div>

          <div className={classes.DataTable}>
            <DataGrid
              sx={{ "& .MuiDataGrid-row:hover": { cursor: "pointer" } }}
              editMode="row"
              onRowClick={({ row }) => navigate(`/Details?id=${row.id}`)}
              columns={headers}
              loading={loading}
              components={{ LoadingOverlay: LinearProgress }}
              rows={data.users}
              pageSize={15}
              rowsPerPageOptions={[15]}
            />
          </div>
        </div>
      </section>
    </Wrapper>
  );
}

const messageTypes = {
  E: "Email",
  S: "SMS",
  N: "Notification",
};

const headers = [
  {
    flex: 0.8,
    field: "recipient",
    headerName: "Recipient",
  },
  {
    flex: 1,
    field: "email",
    headerName: "Email",
  },
];
