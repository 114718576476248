import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

export const FormDialog = ({
  show,
  dialogTitle,
  onAccept,
  onClose,
  acceptLabel,
  children,
  hideDialogActions,
}) => (
  <Dialog open={show} onClose={onClose}>
    <DialogTitle>{dialogTitle}</DialogTitle>

    <DialogContent>{children}</DialogContent>

    {!hideDialogActions && (
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            onClose();
            onAccept();
          }}
        >
          {acceptLabel}
        </Button>
      </DialogActions>
    )}
  </Dialog>
);
