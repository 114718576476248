import React, { useEffect, useRef, useState } from "react";
import classes from "../../src/Style/Tickets.module.css";
import { LinearProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useFetch } from "../hooks/useFetch";
import Wrapper from "../Components/Wrapper";

const limit = 15;

export default function Tickets() {
  const { Fetch } = useFetch();

  let pageTimer = useRef(null);
  const [data, setData] = useState({ count: 0, rows: [] }); // All tickets data
  const [filters, setFilters] = useState({ offset: 0 });
  const [loading, setloading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setloading(true);
      let res = await Fetch(
        "GET",
        `requests?${new URLSearchParams({ ...filters, limit })}`
      );
      setData(res);
      setloading(false);
    };
    getData();
  }, [filters]);

  return (
    <Wrapper bodyFullHeight>
      <section className={classes.BodySection}>
        <div className={classes.DataTableHeader}>
          <h2 className={classes.comptitile}>Tickets</h2>

          <div className={classes.DropdownFilters}>
            <select
              className={classes.Dropdown}
              value={filters.type || "0"}
              onChange={(e) => {
                if (e.target.value == "0") {
                  delete filters.type;
                  setFilters({ ...filters, offset: 0 });
                } else
                  setFilters({ ...filters, type: e.target.value, offset: 0 });
              }}
            >
              <option value="0">Type</option>
              <option value="I">Issue</option>
              <option value="Q">Question</option>
              <option value="S">Suggestion</option>
            </select>

            <select
              className={classes.Dropdown}
              value={filters.status || "0"}
              onChange={(e) => {
                if (e.target.value == "0") {
                  delete filters.status;
                  setFilters({ ...filters, offset: 0 });
                } else
                  setFilters({ ...filters, status: e.target.value, offset: 0 });
              }}
            >
              <option value="0">Status</option>
              <option value="O">Open</option>
              <option value="U">Under Review</option>
              <option value="C">Closed</option>
            </select>
          </div>
        </div>

        <div className={classes.DataTable}>
          <DataGrid
            columns={[
              {
                field: "ticket_id",
                flex: 0.1,
                headerName: "Ticket #",
                renderCell: (row) => (
                  <Link
                    style={{ textDecoration: "none", color: "#0b3758" }}
                    to={`/TicketDetails?id=${row.row.id}`}
                  >
                    {row.value}
                  </Link>
                ),
              },
              {
                field: "email",
                flex: 0.2,
                headerName: "Created by",
                renderCell: (row) => (
                  <div className={classes.creadetby_div}>
                    <span className={classes.name}>
                      {row.row.user.name} {row.row.user.surname}
                    </span>
                    <span>{row.row.email}</span>
                    <span className={classes.date}>@{row.row.createdAt}</span>
                  </div>
                ),
              },
              {
                field: "title",
                flex: 0.2,
                headerName: "Title",
                headerAlign: "center",
                align: "center",
              },
              {
                field: "type",
                flex: 0.1,
                headerName: "Type",
                renderCell: (row) =>
                  row.value == "I"
                    ? "Issue"
                    : row.value == "Q"
                    ? "Question"
                    : row.value == "S"
                    ? "Suggestion"
                    : "",
              },
              { field: "module", flex: 0.2, headerName: "Module" },
              {
                field: "priority",
                flex: 0.1,
                headerName: "Priority",
                align: "center",
                renderCell: (row) =>
                  row.value == "High" ? (
                    <span className={classes.priority_high}>High</span>
                  ) : row.value == "Medium" ? (
                    <span className={classes.priority_medium}>Medium</span>
                  ) : row.value == "Low" ? (
                    <span className={classes.priority_low}>Low</span>
                  ) : (
                    <span className={classes.priority_not}>Not Assigned</span>
                  ),
              },
              {
                field: "status",
                flex: 0.1,
                headerName: "Status",
                align: "center",
                renderCell: (row) =>
                  row.value == "C" ? (
                    <span className={classes.status_closed}>Closed</span>
                  ) : row.value == "O" ? (
                    <span className={classes.status_open}>Open</span>
                  ) : row.value == "U" ? (
                    <span className={classes.status_review}>Under Review</span>
                  ) : (
                    ""
                  ),
              },
            ]}
            loading={loading}
            components={{ LoadingOverlay: LinearProgress }}
            rows={data.rows}
            rowHeight={75}
            pageSize={limit}
            paginationMode="server"
            rowCount={data.count}
            onPageChange={(newPage) => {
              setloading(true);
              clearTimeout(pageTimer.current);
              pageTimer.current = setTimeout(() => {
                setFilters({ ...filters, offset: newPage * limit });
              }, 1500);
            }}
          ></DataGrid>
        </div>
      </section>
    </Wrapper>
  );
}
