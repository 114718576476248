import { Button, CssBaseline, TextField, Box, Container } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useFetch } from "../hooks/useFetch";
import classes from "../../src/Style/Login.module.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0b3758",
    },
  },
});

export default function Login() {
  const { Fetch } = useFetch();
  const [Email, setEmail] = useState("");
  const [forgotEmail, setForgotEmail] = useState("");
  const [code, setCode] = useState("");
  const [Password, setPassword] = useState("");
  const [Loading, setLoading] = useState(false);
  const [auth, setAuth] = useState(false);
  const [forgotPass, setForgotPass] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem("loggedInAdmin")) {
      navigate("/Dashboard");
    }
  });

  const admindata = {
    email: Email,
    password: Password,
  };

  async function login() {
    setLoading(true);
    let res = await Fetch("post", "login", admindata);

    if (res.error) {
      alert("Wrong Email or Password");
      setLoading(false);
    } else {
      if (res.user) {
        sessionStorage.setItem("loggedInAdmin", JSON.stringify(res.user));
        navigate("/Dashboard");
        setLoading(false);
      } else {
        setLoading(false);
        setAuth(true);
      }
    }
  }

  async function verify() {
    setLoading(true);
    let res = await Fetch("post", "verify", { code: code });

    if (res.error) {
      alert("Wrong code");
      setLoading(false);
    } else {
      sessionStorage.setItem("loggedInAdmin", JSON.stringify(res));
      navigate("/Dashboard");
      setLoading(false);
    }
  }

  async function forgotPassword() {
    setLoading(true);

    let res = await Fetch("post", "forgotPassword", { email: forgotEmail });
    console.log(res);
    if(res.error){
      setLoading(false);
      alert(res.error.message)
    }else{
    if (res.success) {
      setLoading(false);
      alert(res.data.message);
      navigate(`/resetPassword?email=${encodeURIComponent(forgotEmail)}`);
    } else {
      setLoading(false);
      alert(res.data.message);
    }}
  }

  return (
    <ThemeProvider theme={theme}>
      {Loading ? (
        <div className={classes.loadingsection}>
          <div className={classes.loadspinner}></div>
        </div>
      ) : null}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className={classes.AppLogo_View}>
            <img
              alt=""
              className={classes.AppLogo}
              src={require("../assets/logoEmpowch.png")}
            />
          </div>
          <Box className={classes.loginbox} Validate sx={{ mt: 3 }}>
            {!forgotPass ? (
              !auth ? (
                <>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Pin Code"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className={classes.forgetDev}>
                    <Button
                      className={classes.fogetButton}
                      onClick={() => setForgotPass(true)}
                    >
                      Forget Password
                    </Button>
                  </div>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 4, mb: 2 }}
                    color="primary"
                    onClick={login}
                  >
                    Log In
                  </Button>
                </>
              ) : (
                <div>
                  <span>Please Enter the 6-digits code from your SMS</span>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="auth"
                    label="Code"
                    name="Code"
                    autoFocus
                    type="numeric"
                    onChange={(r) => setCode(r.target.value)}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 2 }}
                    style={{ float: "right" }}
                    color="success"
                    onClick={verify}
                  >
                    Verify
                  </Button>
                </div>
              )
            ) : (
              <div>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="forgotEmail"
                  label="Email Address"
                  name="forgotEmail"
                  autoComplete="email"
                  autoFocus
                  onChange={(e) => setForgotEmail(e.target.value)}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 4, mb: 2 }}
                  color="primary"
                  onClick={forgotPassword}
                >
                  Send SMS
                </Button>
              </div>
            )}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
