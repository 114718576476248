import React, { useEffect, useRef, useState } from "react";
import classes from "../../src/Style/Connectors.module.css";
import { LinearProgress, Select, MenuItem } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../hooks/useFetch";
import Wrapper from "../Components/Wrapper";
import { generateQueryString } from "../tools/utils";
import Dialogue from "../Components/Dialogue";
import { Messaging } from "../Components/Messaging";
import { handleServerSideSelection } from "../tools/ServerSideSelection";
import { ExportButton } from "../Components/ExportButton";

export default function Connectors() {
  const { Fetch } = useFetch();
  const navigate = useNavigate();
  const [data, setData] = useState({ count: 0, rows: [] });
  const [loading, setloading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState([]);
  const [idList, setIdList] = useState([]);
  let pageTimer = useRef(null);
  let selectionRef = useRef({});

  const ConnectorsProps = {
    page,
  };
  useEffect(() => {
    const getData = async () => {
      setloading(true);
      let queryString = generateQueryString(
        JSON.parse(JSON.stringify(ConnectorsProps))
      );
      let res = await Fetch("GET", `tellers?${queryString}`);
      setData({
        ...res,
        rows: res.tellers.map((tellers) => ({
          ...tellers,
          name: tellers.name || "-----",
          status: status[tellers.status],
          createdAt: new Date(tellers.createdAt).toLocaleString(),
          userName: tellers.user?.name + " " + tellers.user?.surname,
          phone_no: tellers.user?.phone_no
        })),
      });
      // setTotalData([...totalData, ...res.tellers]);
console.log(res)
      setloading(false);
    };
    getData();
  }, [page]);

  const handleSelectionChange = (idList) => {
    let { selectedList, totalIdList } = handleServerSideSelection(
      selectionRef,
      page,
      idList
    );

    selectionRef.current = selectedList;

    console.log(totalIdList);
    setIdList(totalIdList);
  };
  return (
    <Wrapper bodyFullHeight>
      <section className={classes.BodySection}>
        <div className={classes.DataTableHeader}>
          <h2 className={classes.comptitile}>Connectors</h2>
          <div className={classes.DropdownFilters}>
            <Messaging
              userIdList={idList}
              selectedNames={idList}
              userType={"connector"}
            />
          </div>
        </div>
        <ExportButton table={"tellers"}></ExportButton>

        <div className={classes.DataTable}>
          <DataGrid
            sx={{ "& .MuiDataGrid-row:hover": { cursor: "pointer" } }}
            editMode="row"
            onRowClick={({ row }) => navigate(`/ConnectorDetails?id=${row.id}`)}
            columns={headers}
            loading={loading}
            components={{ LoadingOverlay: LinearProgress }}
            rows={data.rows || []}
            rowHeight={75}
            pageSize={limit}
            rowsPerPageOptions={[15]}
            getRowId={(row) => row.user_id}
            paginationMode="server"
            rowCount={data.totalTellers}
            checkboxSelection={true}
            onSelectionModelChange={handleSelectionChange}
            selectionModel={idList}
            onPageChange={(newPage) => {
              clearTimeout(pageTimer.current);
              setloading(true);
              pageTimer.current = setTimeout(() => {
                setPage(newPage);
              }, 1500);
            }}
          ></DataGrid>
        </div>
      </section>
    </Wrapper>
  );
}
const limit = 15;

const status = {
  A: "Active",
  I: "Inactive",
  P: "Pending",
};

const headers = [
  {
    flex: 0.1,
    field: "id",
    headerName: "ID",
  },
  {
    flex: 0.7,
    field: "createdAt",
    headerName: "Date",
  },
  {
    flex: 0.5,
    field: "name",
    headerName: "Business Name",
    
  },
  {
    flex: 0.5,
        field: "phone_no",
        headerName: "Phone Number",
  },
  {
    flex: 0.5,
    field: "userName",
    headerName: "User Name",
  },
  {
    flex: 0.4,
    field: "type",
    headerName: "Type",
  },
  {
    flex: 0.4,
    field: "status",
    headerName: "Status",
  },
  {
    flex: 0.5,
    field: "transactionsCount",
    headerName: "Cash out count",
  },
];
