import React, { useEffect, useRef, useState } from "react";
import classes from "../../src/Style/Messages.module.css";
import { LinearProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../hooks/useFetch";
import Wrapper from "../Components/Wrapper";

export default function Messages() {
  const { Fetch } = useFetch();
  const navigate = useNavigate();

  let pageTimer = useRef(null);
  const [data, setData] = useState({ count: 0, rows: [] });
  const [filters, setFilters] = useState({ offset: 0 });
  const [loading, setloading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setloading(true);

      let res = await Fetch(
        "GET",
        `users/message?${new URLSearchParams({ ...filters, limit })}`
      );

      setData({
        ...res,
        rows: res.rows.map((message) => ({
          ...message,
          subject: message.subject || "-----",
          type: messageTypes[message.type],
          createdAt: new Date(message.createdAt).toLocaleString(),
        })),
      });

      setloading(false);
    };
    getData();
  }, [filters]);

  return (
    <Wrapper bodyFullHeight>
      <section className={classes.BodySection}>
        <div className={classes.DataTableHeader}>
          <h2 className={classes.comptitile}>Messages</h2>

          <div className={classes.DropdownFilters}>
            <select
              className={classes.Dropdown}
              value={filters.type || "0"}
              onChange={(e) => {
                if (e.target.value == "0") {
                  delete filters.type;
                  setFilters({ ...filters, offset: 0 });
                } else
                  setFilters({ ...filters, type: e.target.value, offset: 0 });
              }}
            >
              <option value="0">Type</option>
              <option value="E">Email</option>
              <option value="S">SMS</option>
              <option value="N">Notification</option>
            </select>
          </div>
        </div>

        <div className={classes.DataTable}>
          <DataGrid
            sx={{ "& .MuiDataGrid-row:hover": { cursor: "pointer" } }}
            editMode="row"
            onRowClick={({ row }) => navigate(`/messages/view?id=${row.id}`)}
            columns={headers}
            loading={loading}
            components={{ LoadingOverlay: LinearProgress }}
            rows={data.rows || []}
            rowHeight={75}
            pageSize={limit}
            rowsPerPageOptions={[15]}
            paginationMode="server"
            rowCount={data.count}
            onPageChange={(newPage) => {
              setloading(true);
              clearTimeout(pageTimer.current);
              pageTimer.current = setTimeout(() => {
                setFilters({ ...filters, offset: newPage * limit });
              }, 1500);
            }}
          ></DataGrid>
        </div>
      </section>
    </Wrapper>
  );
}

const limit = 15;

const messageTypes = {
  E: "Email",
  S: "SMS",
  N: "Notification",
};

const headers = [
  {
    flex: 0.7,
    field: "createdAt",
    headerName: "Date",
  },
  {
    flex: 0.4,
    field: "type",
    headerName: "Type",
  },
  {
    flex: 1,
    field: "subject",
    headerName: "Subject",
  },
  {
    flex: 1,
    field: "message",
    headerName: "Message",
  },
];
