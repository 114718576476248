import React, { useState, useEffect, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import config from "../config/config";
import { useFetch } from "../hooks/useFetch";
import Wrapper from "../Components/Wrapper";
import classes from "../Style/Wallet.module.css";
import { generateQueryString } from "../tools/utils";

export default function Wallet() {
  const { Fetch } = useFetch();

  const [loading, setLoading] = useState(false);
  const [autoCompleteLoading, setAutoCompleteLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [sendUserID, setSendUserID] = useState("");
  const [sendAmount, setSendAmount] = useState("");
  const [walletData, setWalletData] = useState({});
  const [transactionsData, setTransactionsData] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [users, setUsers] = useState([]);
  const [autoCompleteSearch, setAutoCompleteSearch] = useState(null);
  const divRef = useRef(null);
  const [copied, setCopied] = useState(false);
  const [page, setPage] = useState(0);
  const [transactionsCount, setTransactionsCount] = useState(0);
  const [selectedOption, setSelectedOption] = useState("out");
  const pageTimer = useRef(null);
  const searchTimer = useRef(null);

  const handleChangeCurrency = (event) => {
    const selectedId = event.target.value;
    const selectedCurrency = currencies.find(
      (currency) => currency.id === selectedId
    );
    setSelectedCurrency(selectedCurrency);
  };

  const handleCopy = () => {
    const text = divRef.current.innerText;
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(true);
        alert("Wallet address copied");
      })
      .catch((error) => {
        console.error("Failed to copy text: ", error);
      });
  };

  const sendMoney = async () => {
    setLoading(true);

    let res = await Fetch("post", "payment/send", {
      userID: parseInt(sendUserID),
      amount: sendAmount,
      currency: selectedCurrency?.code,
    });

    if (res) {
      window.location.reload(false);
    } else {
      alert(res.error);
    }

    setLoading(false);
  };

  const loadData = async () => {
    setLoading(true);

    let res = await Fetch("get", "wallet");

    setCurrencies(res.data.assets);
    setSelectedCurrency(res.data.assets[0]);
    setWalletData({
      address: res.data.walletAddress,
      moreInfo: res.data.moreInfo,
    });
    setLoading(false);
  };

  const getTransactions = async (direction) => {
    setLoadingTable(true);
    let filters = {
      page: page,
      direction: direction ? direction : selectedOption,
    };
    const queryString = generateQueryString(
      JSON.parse(JSON.stringify(filters))
    );

    let res = await Fetch("get", `wallet/transactions?${queryString}`);
    setTransactionsCount(res.totalCount);
    setTransactionsData(res.transactions);
    setLoadingTable(false);
  };

  useEffect(() => {
    getTransactions();
  }, [page]);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    const getUsers = async () => {
      const response = await Fetch(
        "get",
        `users?${new URLSearchParams({
          age: 0,
          date: 0,
          page: 0,
          status: ["A", "P", "I", "D"],
          keyword: autoCompleteSearch,
        })}`
      );

      setAutoCompleteLoading(false);

      setUsers([
        ...response.data.map((i) => {
          return {
            label: `${i.name || ""} ${i.surname || ""}`,
            id: i.id,
          };
        }),
      ]);
    };

    autoCompleteSearch && getUsers();
  }, [autoCompleteSearch]);

  const handleDropdownChange = (e) => {
    setSelectedOption(e.target.value);
    getTransactions(e.target.value);
  };

  function generateUniqueId() {
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 10000);
    const uniqueId = `${timestamp}${random}`;
    return uniqueId;
  }

  return (
    <Wrapper loading={loading} bodyFullHeight>
      <section className={classes.BodySectiond}>
        <div className={classes.DataTableHeader}>
          <h2>Master Wallet</h2>
          <div>
            <label>
              Select Transactions Data:{" "}
              <select
                onChange={handleDropdownChange}
                value={selectedOption}
                style={{ padding: 2, marginRight: 4, borderRadius: 8 }}
              >
                <option value="out">Outgoing</option>
                <option value="in">Incoming</option>
              </select>
            </label>
          </div>
        </div>
        <div className={classes.DataSection}>
          <div className={classes.MasterWallet}>
            <div className={classes.MasterWallet_info}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <span style={{ color: "#0b3758" }}>
                  Total Balance : {Number(selectedCurrency.balance).toFixed(2)}
                </span>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedCurrency.id || 1}
                  label="Currency"
                  onChange={(e) => handleChangeCurrency(e)}
                  style={{ height: "50%", marginLeft: 5, color: "#0b3758" }}
                  defaultValue={selectedCurrency.id || 1}
                >
                  {currencies.map((i) => {
                    return (
                      <MenuItem key={i.id} value={i.id}>
                        {i.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            </div>
            <div className={classes.MasterWallet_send}>
              <Autocomplete
                disablePortal
                id="userSelection"
                onChange={(e, v) => setSendUserID(v.id)}
                options={users}
                loading={autoCompleteLoading}
                filterOptions={(x) => x}
                onInputChange={(e, v) => {
                  setUsers([]);
                  clearTimeout(searchTimer.current);
                  setAutoCompleteLoading(true);
                  searchTimer.current = setTimeout(() => {
                    setAutoCompleteSearch(v);
                  }, 1000);
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    {...props}
                  >
                    <Box sx={{ flex: 1 }}>{option.label}</Box>
                    <Box>{option.id}</Box>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} label={"User"} />
                )}
              />
              <TextField
                label="Amount"
                className={classes.textField}
                value={sendAmount}
                onChange={(e) => setSendAmount(e.target.value)}
              />

              <Button
                variant="contained"
                className={classes.buttonStyle}
                style={{ height: "max-content" }}
                onClick={sendMoney}
                disabled={!sendUserID || !selectedCurrency || !sendAmount}
              >
                Send
              </Button>
            </div>
          </div>

          <div className={classes.UserWallet}>
            <div style={{ height: "95%" }}>
              <div
                style={{ padding: 5 }}
                ref={divRef}
                onClick={handleCopy}
                id="wallet_address"
              >
                <span style={{ fontSize: 14, flexWrap: "wrap" }}>
                  {" "}
                  Master Wallet Address: {walletData.address}
                </span>
              </div>
              {selectedOption === "out" ? (
                <DataGrid
                  columns={[
                    {
                      field: "id",
                      flex: 0.1,
                      headerName: "ID",
                      align: "center",
                    },
                    {
                      field: "createdAt",
                      flex: 0.2,
                      headerName: "Date",
                      renderCell: (row) =>
                        row.value.replace("T", " ,").split(".", [1]),
                    },
                    {
                      field: "amount",
                      flex: 0.15,
                      headerName: "Amount",
                      renderCell: (row) =>
                        `${row.value?.toFixed(2)} ${
                          row.row.sentCurrency?.name || "USD"
                        }`,
                    },
                    {
                      field: "receiver",
                      flex: 0.25,
                      headerName: "Receiver",
                      renderCell: (row) => (
                        <span>
                          {row.row.receiver
                            ? row.row.receiver.name +
                              " " +
                              row.row.receiver.surname
                            : "--- No NAME ---"}
                        </span>
                      ),
                    },
                    {
                      field: "status",
                      flex: 0.2,
                      headerName: "Status",
                      renderCell: (row) => {
                        let statusLabel = "";
                        let statusColor = "";

                        switch (row.value) {
                          case "S":
                            statusLabel = "Complete";
                            statusColor = "#0b3758";
                            break;
                          case "P":
                            statusLabel = "Pending";
                            statusColor = "#64c4b6";
                            break;
                          case "R":
                            statusLabel = "Rejected";
                            statusColor = "#60100B";
                            break;
                          case "C":
                            statusLabel = "Canceled";
                            statusColor = "#747474";
                            break;
                          case "F":
                            statusLabel = "Failed";
                            statusColor = "red";
                            break;
                          default:
                            break;
                        }

                        return (
                          <a
                            target="_blank"
                            style={{
                              color: statusColor,
                              textDecoration: "none",
                            }}
                          >
                            {statusLabel}
                          </a>
                        );
                      },
                    },
                  ]}
                  rows={transactionsData}
                  loading={loadingTable}
                  pageSize={15}
                  rowCount={transactionsCount}
                  paginationMode="server"
                  onPageChange={(newPage) => {
                    clearTimeout(pageTimer.current);
                    setLoadingTable(true);
                    pageTimer.current = setTimeout(() => {
                      setPage(newPage);
                    }, 1500);
                  }}
                ></DataGrid>
              ) : (
                <DataGrid
                  columns={[
                    {
                      field: "createdAt",
                      flex: 0.2,
                      headerName: "Date",
                      renderCell: (row) =>
                        row.value.replace("T", " ,").split(".", [1]),
                    },
                    {
                      field: "sender",
                      flex: 0.25,
                      headerName: "From",
                      renderCell: (row) => (
                        <span>
                          {row.row.sender
                            ? row.row.sender.name + " " + row.row.sender.surname
                            : "--- No NAME ---"}
                        </span>
                      ),
                    },
                    {
                      field: "fee",
                      flex: 0.15,
                      headerName: "Fee Amount",
                      renderCell: (row) => (
                        <span>
                          {row.value} {row.row.sentCurrency.name}
                        </span>
                      ),
                    },
                  ]}
                  rows={transactionsData}
                  loading={loadingTable}
                  pageSize={15}
                  getRowId={generateUniqueId}
                  rowCount={transactionsCount}
                  paginationMode="server"
                  onPageChange={(newPage) => {
                    clearTimeout(pageTimer.current);
                    setLoadingTable(true);
                    pageTimer.current = setTimeout(() => {
                      setPage(newPage);
                    }, 1500);
                  }}
                ></DataGrid>
              )}
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
}
