import config from "../config/config";

const SERVER_API_URL = config.API_URL;

export const useFetch = () => {
  const validateResponse = async (response) => {
    if (response.status == 401) {
      sessionStorage.clear();
      window.location.href = "/";
    }
    try {
      response = await response.json();
    } catch (err) {
      console.log(err);
      response = {};
    }

    return response;
  };

  const Download = async (endpoint = "") => {
    const data = JSON.parse(sessionStorage.getItem("loggedInAdmin") || "{}");

    const response = await fetch(SERVER_API_URL + endpoint, {
      method: "GET",
      headers: {
        Authorization: data.token || "",
      },
    });

    if (response.status === 200) {
      const contentDisposition = response.headers.get('Content-Disposition');
      const filename = contentDisposition ? contentDisposition.split('filename=')[1] : 'report.xlsx';
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename.replace(/"/g, ""));
      link.click();
      URL.revokeObjectURL(url);
    } else {
      console.error("Error downloading file");
    }
  };

  const Fetch = async (method = "", endpoint = "", body = {}) => {
    const data = JSON.parse(sessionStorage.getItem("loggedInAdmin")||"{}")

    method = method.toUpperCase();
    body = method == "GET" ? {} : { body: JSON.stringify(body) };

    let response = await fetch(SERVER_API_URL + endpoint, {
      method: method,
      credentials: "include",
      headers: {
        "Content-type": "application/json",
        Authorization: data.token || "",
      },
      ...body,
    });
    response = await validateResponse(response);

    return response;
  };

  return { Fetch, Download };
};
