import { FileDownload } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import { useFetch } from "../hooks/useFetch";
import { useState } from "react";

export function ExportButton({ table }) {
  const { Download } = useFetch();
  const [loading, setLoading] = useState(false);

  const handleExport = async () => {
    setLoading(true);
    try {
      await Download(`export?table=${table}`);
    } catch (error) {
      console.error("Error exporting file", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button style={{ color: "green" }} onClick={handleExport} disabled={loading}>
        {loading ? <CircularProgress size={15} style={{color: 'green', marginRight: 5}} /> : <FileDownload />}
        Export
      </Button>
    </div>
  );
}