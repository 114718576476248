import { useState } from "react";
import { Select, MenuItem } from "@mui/material";
import Dialogue from "./Dialogue";

const MessagingTypes = ["Email", "SMS", "Notification"];

export function Messaging({ userIdList, selectedNames, userType}) {
  const [messagingType, setMessagingType] = useState("");
  const [openDialogue, setOpenDialogue] = useState(false);

  return (
    <div>
      <Dialogue
        open={openDialogue}
        onClose={() => setOpenDialogue(false)}
        type={messagingType.toLowerCase()}
        id={userIdList}
        names={selectedNames}
        userType={userType}
      />

      <Select
        renderValue={() => <span>Send Message</span>}
        displayEmpty={true}
        value={null}
        onChange={(e) => {
          setMessagingType(e.target.value);
          setOpenDialogue(true);
        }}
      >
        {MessagingTypes.map((type, index) => (
          <MenuItem key={index} value={type}>
            {type}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
