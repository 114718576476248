import React, { useEffect, useState } from "react";
import classes from "../../src/Style/Settings.module.css";
import { DataGrid } from "@mui/x-data-grid";
import { Button, styled, LinearProgress } from "@mui/material";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useFetch } from "../hooks/useFetch";
import Wrapper from "../Components/Wrapper";

export default function Settings() {
  const { Fetch } = useFetch();

  const [data, setData] = useState([]);
  const [loading, setloading] = useState(false);
  const loggedInAdmin = JSON.parse(
    window.sessionStorage.getItem("loggedInAdmin")
  );

  async function Load_data() {
    setloading(true);

    let res = await Fetch("get", "admins");
    setData(res);

    setloading(false);
  }

  useEffect(() => {
    Load_data();
  }, []);

  return (
    <Wrapper bodyFullHeight>
      <section className={classes.BodySection}>
        <div className={classes.DataTableHeader}>
          <h2 className={classes.comptitile}>Settings</h2>
        </div>
        <div className={classes.DataTable}>
          <DataGrid
            columns={[
              {
                field: "email",
                flex: 0.3,
                headerName: "E-mail",
              },
              {
                field: "fullname",
                headerName: "Name",
                flex: 0.2,
                renderCell: (row) => row.row.name + " " + row.row.surname,
              },
              {
                field: "last_login",
                headerName: "Last Login",
                flex: 0.2,
              },

              {
                field: "active",
                flex: 0.1,
                headerName: "Status",
                renderCell: (row) =>
                  loggedInAdmin.role === "A" ? (
                    row.value ? (
                      <span style={{ color: "#2D90EC" }}>Active</span>
                    ) : (
                      <span style={{ color: "#747474" }}>Not Active</span>
                    )
                  ) : loggedInAdmin.role === "S" ? (
                    row.value ? (
                      <DeactivateButton
                        size="small"
                        variant="outlined"
                        className={classes.btn}
                        color="error"
                        onClick={() => deactivate_button(row.row.id)}
                        disabled={loggedInAdmin.role != "S"}
                      >
                        Deactivate
                      </DeactivateButton>
                    ) : (
                      <ActivateButton
                        size="small"
                        variant="outlined"
                        disabled={!row.value == "S"}
                        onClick={() => activate_button(row.row.id)}
                      >
                        Activate
                      </ActivateButton>
                    )
                  ) : (
                    ""
                  ),
              },
              {
                field: "role",
                flex: 0.1,
                headerName: "Role",
                renderCell: (row) =>
                  row.value == "S" ? (
                    <span style={{ color: "#0B3758" }}>Super Admin</span>
                  ) : (
                    <span style={{ color: "#0B3758" }}> Admin</span>
                  ),
              },
            ]}
            rows={data}
            loading={loading}
            components={{
              LoadingOverlay: LinearProgress,
            }}
          ></DataGrid>
        </div>
      </section>
    </Wrapper>
  );

  function deactivate_button(id) {
    confirmAlert({
      title: "Confirm to Deactivate user",
      message: "Are you sure that you want to de-activate this user ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => userActivation(id, false),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  function activate_button(id) {
    confirmAlert({
      title: "Confirm to Activate user",
      message: "Are you sure that you want to activate this user ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => userActivation(id, true),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  async function userActivation(id, active) {
    await Fetch("post", "admins_status", {
      active: active,
      adminID: id,
    });
    Load_data();
  }
}

const DeactivateButton = styled(Button)({
  color: "#FF4C4C",
  fontSize: 12,
  padding: "6px 12px",
  border: "1px solid #FFC4C4",
  boxSizing: "border-box",
  borderRadius: 10,
  lineHeight: 1.5,
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
    border: "1px solid #FFC4C4",
  },
});

const ActivateButton = styled(Button)({
  color: "#64c4b6",
  fontSize: 12,
  padding: "6px 12px",
  border: "1px solid #64c4b6",
  boxSizing: "border-box",
  borderRadius: 10,
  lineHeight: 1.5,
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
    border: "1px solid #64c4b6",
  },
});
