import React from "react";

import classes from "../Style/Wrapper.module.css";
import Sidebar from "./Sidebar";
import Header from "./Header";

export default function Wrapper({
  loading = false,
  bodyFullHeight = false,
  children,
}) {
  if (!sessionStorage.getItem("loggedInAdmin")) {
    window.location.href = "/";
  }
  return (
    <div className={classes.container}>
      {loading && (
        <div className={classes.loadingsection}>
          <div className={classes.loadspinner}></div>
        </div>
      )}

      <Sidebar />

      <div className={classes.bodyWithHeader}>
        <Header />

        <div className={classes.bodyContainer}>
          <div
            className={`${classes.body} ${
              bodyFullHeight && classes.bodyFullHeight
            }`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
