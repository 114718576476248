/* eslint-disable react/style-prop-object */
import React, { useState, useEffect, useRef, version } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Avatar,
  Button,
  LinearProgress,
  TextField,
  Menu,
  MenuItem,
} from "@mui/material";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import HourglassTopOutlinedIcon from "@mui/icons-material/HourglassTopOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import SearchIcon from "@mui/icons-material/Search";
import FlagIcon from "@mui/icons-material/Flag";
import { Link } from "react-router-dom";

import { generateQueryString } from "../tools/utils";
import classes from "../Style/Customers.module.css";
import { countries } from "../data/countries";
import Dialogue from "../Components/Dialogue";
import { useFetch } from "../hooks/useFetch";
import Wrapper from "../Components/Wrapper";
import config from "../config/config";
import { ExportButton } from "../Components/ExportButton";

function Customers() {
  const { Fetch } = useFetch();

  const [data, setData] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [loading, setloading] = useState(false);
  const [languageFilter, setLanguageFilter] = useState(undefined);
  const [genderFilter, setGenderFilter] = useState(undefined);
  const [versionFilter, setVersionFilter] = useState(undefined);
  const [status, setstatus] = useState(undefined);
  const [date, setdate] = useState(undefined);
  const [countryfilter, setcountryfilter] = useState([]);
  const [selectedcountry, setselectedcountry] = useState(undefined);
  const [searchdata, setsearchdata] = useState([]);
  const [age, setage] = useState(undefined);
  const [openDialog, setopenDialog] = useState(false);
  const [multi_id, setmulti_id] = useState([]);
  const [type, settype] = useState("");
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState(undefined);
  const [sort, setSort] = useState(undefined);
  const [card, setcard] = useState(false);
  const [x, setx] = useState(0);
  const [y, sety] = useState(0);
  const [bankname, setbankname] = useState("");
  const [plaidnumber, setplaidnumber] = useState("");
  const [bank_accountype, setbank_accounttype] = useState("");
  const [bank_accountname, setbank_accountname] = useState("");
  const [score, setscore] = useState("");
  const [flagmodule, setflagmodule] = useState("");
  const [flagdescription, setflagdescription] = useState("");
  const [flagcard, setflagcard] = useState(false);
  const [selectedNames, setSelectedNames] = useState([]);
  const [totalData, setTotalData] = useState([]);
  let currentDate = new Date().getTime();
  let pageTimer = useRef(null);
  let searchTimer = useRef(null);
  let prevSelection = useRef(null);

  function getFullName(params) {
    return `${params.row.name || "Null"} ${params.row.surname || "Null"}`;
  } // concatinate user full name

  function showCoords(event) {
    setx(event.pageX);
    sety(event.pageY);
  }

  function filterstatus(e) {
    const value = e.target.value;
    setstatus(value === "0" ? undefined : value);
  }

  function filterdate(e) {
    const value = e.target.value;
    setdate(value === "0" ? undefined : value);
  }
  function filtercountry(e) {
    const value = e.target.value;
    setselectedcountry(value === "0" ? undefined : value);
  }
  function filterage(e) {
    const value = e.target.value;
    setage(value === "0" ? undefined : value);
  }
  function Search(e) {
    const value = e.target.value;
    setKeyword(value === "" ? undefined : value);
  }
  const usersProp = {
    page,
    keyword,
    status,
    date,
    age,
    sortBy: sort,
    country: selectedcountry,
    language: languageFilter,
    gender: genderFilter,
    app_version: versionFilter,
  };

  async function Load_data() {
    if (searchdata.length == 0) {
      setloading(true);

      let queryString = generateQueryString(
        JSON.parse(JSON.stringify(usersProp))
      );
      let res = await Fetch("get", `users?${queryString}`);
      setTotalData((total) => {
        if (total.length > 0) {
          return [...total, ...res.data];
        } else {
          return [...data, ...res.data];
        }
      });
      setData(res.data);
      setUserCount(res.totalCount);

      setcountryfilter((countryfilter) => {
        countryfilter = [];
        for (let code of res.countries) {
          let country = countries[code.toUpperCase()];
          if (country) countryfilter.push({ code, country });
        }

        countryfilter = countryfilter.sort((a, b) =>
          a.country > b.country ? 1 : -1
        );

        return countryfilter;
      });

      setloading(false);
    }
  }

  const [multi_anchorEl, setmulti_AnchorEl] = useState(null);
  const open_2 = Boolean(multi_anchorEl);

  const handleClick_2 = (event) => {
    setmulti_AnchorEl(event.currentTarget);
  };

  const handleClose_2 = () => {
    setmulti_AnchorEl(null);
  };

  useEffect(() => {
    Load_data();
  }, [
    status,
    date,
    selectedcountry,
    keyword,
    age,
    page,
    sort,
    languageFilter,
    genderFilter,
    versionFilter,
  ]);

  return (
    <Wrapper bodyFullHeight>
      <div>
        {openDialog ? (
          <Dialogue
            open={true}
            onClose={() => {
              setopenDialog(false);
            }}
            filters={JSON.parse(JSON.stringify(usersProp))}
            type={type}
            id={multi_id}
            names={selectedNames}
          ></Dialogue>
        ) : (
          ""
        )}
      </div>
      <section className={classes.BodySection}>
        <div
          className={classes.usercard}
          style={{
            display: card ? "flex" : "none",
            top: y - 195,
            left: x - 55,
          }}
        >
          <div className={classes.UserDetails_Data_Row}>
            <span className={classes.UserDetails_Data_Title}>Bank Name</span>
            <span className={classes.UserDetails_Data_Result}>{bankname}</span>
          </div>
          <div className={classes.UserDetails_Data_Row}>
            <span className={classes.UserDetails_Data_Title}>Plaid Number</span>
            <span className={classes.UserDetails_Data_Result}>
              **********{plaidnumber}
            </span>
          </div>
          <div className={classes.UserDetails_Data_Row}>
            <span className={classes.UserDetails_Data_Title}>Account Name</span>
            <span className={classes.UserDetails_Data_Result}>
              {bank_accountname}
            </span>
          </div>
          <div className={classes.UserDetails_Data_Row}>
            <span className={classes.UserDetails_Data_Title}>Account Type</span>
            <span className={classes.UserDetails_Data_Result}>
              {bank_accountype}
            </span>
          </div>
          <div className={classes.UserDetails_Data_Row}>
            <span className={classes.UserDetails_Data_Title}>Score</span>
            <span className={classes.UserDetails_Data_Result}>{score}</span>
          </div>
        </div>

        {/* //////////// */}
        <div
          className={classes.usercard}
          style={{
            display: flagcard ? "flex" : "none",
            top: y - 130,
            left: x - 55,
          }}
        >
          <div className={classes.UserDetails_Data_Row}>
            <span className={classes.UserDetails_Data_Title}>Module</span>
            <span className={classes.UserDetails_Data_Result}>
              {flagmodule ? flagmodule : "SILA"}
            </span>
          </div>
          <div className={classes.UserDetails_Data_Row}>
            <span className={classes.UserDetails_Data_Title}>Description</span>
            <span className={classes.UserDetails_Data_Result}>
              {flagdescription ? flagdescription : "Doc KYC Failed"}
            </span>
          </div>
        </div>

        {/* //////////// */}
        <div className={classes.DataTableHeader}>
          <h2 className={classes.comptitile}>Customers</h2>
          <div style={{ width: "40%" }}>
            <TextField
              className={classes.Search}
              placeholder={"Search"}
              InputProps={{
                type: "search",
                startAdornment: <SearchIcon />,
              }}
              onChange={(e) => {
                clearTimeout(searchTimer.current);
                setloading(true);
                searchTimer.current = setTimeout(() => {
                  Search(e);
                }, 1000);
              }}
              size="small"
              style={{ width: "100%" }}
            />
          </div>

          <div className={classes.DropdownFilters}>
            <Button
              id="demo-positioned-button"
              aria-controls={open_2 ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open_2 ? "true" : undefined}
              onClick={handleClick_2}
              style={{
                color: "#000",
                backgroundColor: "#FFF",
                width: 25,
                height: 30,
                right: 7,
                borderRadius: 5,
                borderWidth: 0.7,
                borderColor: "#000",
                borderStyle: "solid",
              }}
            >
              Send
            </Button>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={multi_anchorEl}
              open={open_2}
              onClose={handleClose_2}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{
                elevation: 1,
                sx: {
                  overflow: "visible",
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    left: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  settype("sms");
                  send_sms_button();
                }}
              >
                SMS
              </MenuItem>
              <MenuItem
                onClick={() => {
                  settype("notification");
                  send_sms_button();
                }}
              >
                Notification
              </MenuItem>
              <MenuItem
                onClick={() => {
                  settype("email");
                  send_sms_button();
                }}
              >
                E-mail
              </MenuItem>
            </Menu>

            <select
              className={classes.Dropdown}
              value={date}
              onChange={filterdate}
            >
              <option value="0">Date</option>
              <option value="today">Today</option>
              <option value="lastweek">Last Week</option>
              <option value="lastmonth">Last Month</option>
              <option value="lastyear">Last Year</option>
            </select>
            <select
              className={classes.Dropdown}
              value={status}
              onChange={filterstatus}
            >
              <option value="0">Status</option>
              <option value="A">Verified</option>
              <option value="P">Pending</option>
              <option value="I">Not Active</option>
              <option value="C">Incomplete</option>
              <option value="D">Deactivated</option>
            </select>
            <select
              className={classes.Dropdown}
              value={age}
              onChange={filterage}
            >
              <option value="0">Age</option>
              <option value="age1">15 To 25</option>
              <option value="age2">26 To 40</option>
              <option value="age3">40 To 55</option>
              <option value="age4">Older than 55</option>
            </select>
            <select
              className={classes.Dropdown}
              value={selectedcountry}
              onChange={filtercountry}
            >
              <option value="0">Countries</option>
              {countryfilter.map(({ code, country }, index) => (
                <option key={index} value={code}>
                  {country}
                </option>
              ))}
            </select>
            <select
              className={classes.Dropdown}
              value={languageFilter}
              onChange={(e) => {
                let value = e.target.value;
                setLanguageFilter(value === "0" ? undefined : value);
              }}
            >
              <option value="0">Language</option>
              <option value="En">English</option>
              <option value="Ar">Arabic</option>
              <option value="Es">Spanish</option>
            </select>

            <select
              className={classes.Dropdown}
              value={genderFilter}
              onChange={(e) => {
                let value = e.target.value;
                setGenderFilter(value === "0" ? undefined : value);
              }}
            >
              <option value="0">Gender</option>
              <option value="M">Male</option>
              <option value="F">Female</option>
            </select>
            <select
            className={classes.Dropdown}
            value={versionFilter}
            onChange={(e) => {
              console.log(e);
              let value = e.target.value;
              setVersionFilter(value === "0" ? undefined : value);
            }}
          >
            <option value="0">All</option>
            <option value="latestVersion">Latest Version</option>
            <option value="oldVersion">Old Version</option>
          </select>
          </div>
        </div>
        <ExportButton table={"users"}></ExportButton>
        <div className={classes.DataTable}>
          <DataGrid
            columns={[
              {
                field: "fullname",
                headerName: "Name",
                flex: 0.2,
                sortable: false,
                renderCell: (row) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Avatar
                      sx={{
                        width: 40,
                        height: 40,
                        border:
                          row.row.status == "D"
                            ? "2px solid #60100b"
                            : row.row.kyc_status == "kyc_none" ||
                              row.row.kyc_status == "lite_kyc_inProgress"
                            ? "2px solid #747474"
                            : row.row.kyc_status == "lite_kyc_passed"
                            ? "2px solid #64c4b6"
                            : row.row.kyc_status == "doc_kyc_inProgress" ||
                              row.row.kyc_status == "doc_kyc_failed"
                            ? "2px solid  #FFA500"
                            : row.row.kyc_status == "doc_kyc_passed"
                            ? "2px solid  #0b3758"
                            : "",
                      }}
                      src={
                        row.row.avatar
                          ? config.DOC_SERVER + row.row.avatar
                          : "/broken-image.jpg"
                      }
                    ></Avatar>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span style={{ alignSelf: "center", marginLeft: 5 }}>
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "#0b3758",
                          }}
                          to={`/Details?id=${row.row.id}`}
                        >
                          {row.value}
                        </Link>
                      </span>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: 12,
                          color: "lightgrey",
                          marginLeft: 5,
                        }}
                      >
                        <span>{row.row.createdAt.split(",")[0]}</span>
                        <span>{row.row.createdAt.split(",")[1]}</span>
                      </div>
                    </div>
                  </div>
                ),
                valueGetter: getFullName,
              },

              {
                field: "country_code",
                flex: 0.1,
                headerName: "Country",
                // renderCell: (row) => regionNames.of(row.value.toUpperCase()),
              },
              {
                field: "id",
                flex: 0.1,
                headerName: "ID",
              },
              {
                field: "kyc_status",
                flex: 0.19,
                headerName: "Status",
                renderCell: (row) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {row.row.status == "D" ? (
                      // <span style={{ color: "#60100B" }}>De-activated</span>
                      <BlockOutlinedIcon htmlColor="#60100B" />
                    ) : row.value == "kyc_none" ||
                      row.value == "lite_kyc_inProgress" ? (
                      <span style={{ color: "#747474" }}>--</span>
                    ) : row.value == "lite_kyc_passed" ? (
                      // <span style={{ color: "#64c4b6" }}>Pending</span>
                      <AssignmentIndIcon htmlColor="#64c4b6" />
                    ) : row.value == "doc_kyc_inProgress" ? (
                      <>
                        {/* <span style={{ color: "#FFA500" }}>Pending</span> */}
                        <HourglassTopOutlinedIcon color="warning" />
                      </>
                    ) : row.value == "doc_kyc_failed" ? (
                      <>
                        {/* <span style={{ color: "#FFA500" }}>Pending</span> */}
                        <FlagIcon
                          color="error"
                          onMouseMove={showCoords}
                          onMouseEnter={() => {
                            setflagcard(true);
                          }}
                          onMouseLeave={() => {
                            setflagcard(false);
                          }}
                        />
                      </>
                    ) : row.value == "doc_kyc_passed" ? (
                      <>
                        {/* <span style={{ color: "#0b3755" }}>Verified</span> */}
                        <VerifiedOutlinedIcon color="success" />
                      </>
                    ) : (
                      ""
                    )}
                    {row.row.account.length != 0 ? (
                      <AccountBalanceOutlinedIcon
                        color="primary"
                        onMouseMove={showCoords}
                        onMouseEnter={() => {
                          setcard(true);
                          setbankname(
                            row.row.account
                              ? row.row.account[0].plaid.institution.name
                              : ""
                          );
                          setplaidnumber(
                            row.row.account
                              ? row.row.account[0].plaid.accounts[0].meta.number
                              : ""
                          );
                          setbank_accountname(
                            row.row.account
                              ? row.row.account[0].plaid.accounts[0].meta.name
                              : ""
                          );
                          setbank_accounttype(
                            row.row.account
                              ? row.row.account[0].plaid.accounts[0].subtype
                              : ""
                          );
                          setscore(
                            row.row.account
                              ? row.row.account[0].plaid.score
                              : ""
                          );
                        }}
                        onMouseLeave={() => {
                          setcard(false);
                        }}
                      />
                    ) : (
                      ""
                    )}
                    {row.row.logs.length != 0 ? (
                      <>
                        <FlagIcon
                          color="error"
                          onMouseMove={showCoords}
                          onMouseEnter={() => {
                            setflagcard(true);
                            setflagmodule(row.row.logs[0].module);
                            setflagdescription(
                              row.row.logs[0].description.split(",")[0]
                            );
                          }}
                          onMouseLeave={() => {
                            setflagcard(false);
                            setflagmodule("");
                            setflagdescription("");
                          }}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ),
              },
              {
                field: "phone_no",
                flex: 0.15,
                headerName: "Phone number",
              },

              {
                field: "birth_date",
                flex: 0.05,
                headerName: "Age",

                renderCell: (row) =>
                  row.row.birth_date
                    ? Math.floor(
                        (currentDate - new Date(row.row.birth_date).getTime()) /
                          31536000000
                      )
                    : "-",
              },
              {
                field: "gender",
                flex: 0.06,
                headerName: "Gender",
                align: "center",
                renderCell: (row) => (row.value ? row.value : "-"),
              },
              {
                field: "language",
                flex: 0.08,
                headerName: "Language",
                align: "center",
                renderCell: (row) => (row.value ? row.value : "-"),
              },
              {
                field: "app_version",
                flex: 0.08,
                headerName: "App Version",
                align: "center",
                renderCell: (row) => (row.value ? row.value : "-"),
              },
            ]}
            rows={searchdata.length != 0 ? searchdata : data}
            pageSize={15}
            paginationMode="server"
            sortingMode="server"
            onSortModelChange={(e) => {
              setSort(e[0]);
            }}
            rowsPerPageOptions={[15]}
            rowCount={userCount}
            loading={loading}
            onPageChange={(newPage) => {
              clearTimeout(pageTimer.current);
              setloading(true);
              pageTimer.current = setTimeout(() => {
                setPage(newPage);
              }, 1500);
            }}
            rowHeight={75}
            getRowId={(row) => row.id}
            checkboxSelection={true}
            components={{
              LoadingOverlay: LinearProgress,
            }}
            keepNonExistentRowsSelected={true}
            onSelectionModelChange={(e) => {
              let removeSelection = null;
              if (prevSelection.current?.data) {
                prevSelection.current.data.forEach((i) => {
                  if (
                    prevSelection.current.search === keyword &&
                    prevSelection.current.page === page &&
                    !e.includes(i)
                  ) {
                    removeSelection = i;
                  }
                });
              }
              prevSelection.current = {
                data: e,
                page: page,
                search: keyword,
              };

              let selectedList = new Set([
                ...multi_id.filter((i) => i !== removeSelection),
                ...e,
              ]);

              let selectedNames = [...selectedList].reduce((names, i) => {
                let rowData = totalData.find((j) => j.id === i);
                if (rowData) {
                  names.push(rowData.name + " " + rowData.surname);
                }
                return names;
              }, []);

              setSelectedNames(selectedNames);
              setmulti_id([...selectedList]);
            }}
          />
        </div>
      </section>
    </Wrapper>
  );
  function send_sms_button() {
    setopenDialog(true);
  }
}

export default Customers;
