import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom";
import React, { createContext } from "react";
import ConnectorDetails from "./screens/ConnectorDetails";
import TicketDetails from "./screens/TicketDetails";
import Transactions from "./screens/Transactions";
import ViewMessage from "./screens/ViewMessage";
import Connectors from "./screens/Connectors"; 
import Dashboard from "./screens/Dashboard";
import Customers from "./screens/Customers";
import Business from "./screens/Business";
import Messages from "./screens/Messages";
import Settings from "./screens/Settings";
import Details from "./screens/Details";
import Tickets from "./screens/Tickets";
import Wallet from "./screens/Wallet";
import config from "./config/config";
import Login from "./screens/Login";
import Resetpassword from "./screens/Resetpassword";
import Purchases from "./screens/Purchases";
import BusinessDetails from "./screens/BusinessDetails";
import "./Style/App.css";

export const GlobalContext = createContext({});

function Index() {
  return (
    <React.StrictMode>
      <div className="App">
        <section className="mainsection">
          <GlobalContext.Provider value={{}}>
            <Router basename={config.BASENAME}>
              <Routes>
                <Route exact path="/" element={<Login />} />
                <Route exact path="/Login" element={<Login />} />
                <Route exact path="/Transactions" element={<Transactions />} />
                <Route exact path="/Purchases" element={<Purchases />} />
                <Route exact path="/Dashboard" element={<Dashboard />} />
                <Route exact path="/Customers" element={<Customers />} />
                <Route exact path="/Details" element={<Details />} />
                <Route exact path="/Settings" element={<Settings />} />
                <Route exact path="/Tickets" element={<Tickets />} />
                <Route exact path="/messages" element={<Messages />} />
                <Route exact path="/messages/view" element={<ViewMessage />} />
                <Route exact path="/Connectors" element={<Connectors />} />
                <Route exact path="/ConnectorDetails" element={<ConnectorDetails />} />
                <Route
                  exact
                  path="/TicketDetails"
                  element={<TicketDetails />}
                />
                <Route exact path="/Business" element={<Business />} />
                <Route exact path="/business/:id" element={<BusinessDetails />} />
                <Route exact path="/Wallet" element={<Wallet />} />
                <Route exact path="/resetPassword" element={<Resetpassword />} />
              </Routes>
            </Router>
          </GlobalContext.Provider>
        </section>
      </div>
    </React.StrictMode>
  );
}

ReactDOM.render(<Index />, document.getElementById("root"));
