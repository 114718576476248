export function handleServerSideSelection(selectionRef, page, idList) {
  if (!selectionRef.current) selectionRef.current = {};

  let selectedList = { ...selectionRef.current };
  selectedList[page] = idList;

  let totalIdList = groupSelectionInList(selectedList);

  return { selectedList, totalIdList };
}

/**
 * Group all selected ids in a single list
 * @param {Object} selectionObject
 * @returns {Array} - List of all selected ids
 */
function groupSelectionInList(selectionObject) {
  let idList = [];
  for (let key in selectionObject) {
    idList = [...new Set([...idList, ...selectionObject[key]])];
  }
  return idList;
}
