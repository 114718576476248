import classes from "../Style/UserCard.module.css";

export const UserCard = ({display, pos, details}) => {
  return (
    <div
      className={classes.usercard}
      style={{
        display: display ? "flex" : "none",
        top: pos.y,
        left: pos.x,
      }}
    >
      <div className={classes.UserDetails_Data_Row}>
        <span className={classes.UserDetails_Data_Title}>Name</span>
        <span className={classes.UserDetails_Data_Result}>{details.name}</span>
      </div>
      <div className={classes.UserDetails_Data_Row}>
        <span className={classes.UserDetails_Data_Title}>ID</span>
        <span className={classes.UserDetails_Data_Result}>{details.id}</span>
      </div>
      <div className={classes.UserDetails_Data_Row}>
        <span className={classes.UserDetails_Data_Title}>Phone Number</span>
        <span className={classes.UserDetails_Data_Result}>{details.phone_no}</span>
      </div>
      <div className={classes.UserDetails_Data_Row}>
        <span className={classes.UserDetails_Data_Title}>E-mail</span>
        <span className={classes.UserDetails_Data_Result}>{details.email}</span>
      </div>
    </div>
  );
};
