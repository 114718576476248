import { Button, CssBaseline, TextField, Box, Container } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useFetch } from "../hooks/useFetch";
import classes from "../../src/Style/Login.module.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0b3758",
    },
  },
});

export default function ResetPassword() {
  const { Fetch } = useFetch();
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  // Assuming the email is passed as a query parameter or from another source
  const email = new URLSearchParams(window.location.search).get("email");

  async function resetPassword() {
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    setLoading(true);
    let res = await Fetch("post", "resetPassword", {
      email,
      code,
      password,
      confirmPassword,
    });
console.log(res)
    if (res.error) {
      alert(res.error.message);
    } else {
      if (res.success) {
        alert("Password has been successfully reset.");
        navigate("/login"); // Navigate to login page after successful reset
      } else {
        alert(res.data.message);

      }
    }
    setLoading(false);
  }

  return (
    <ThemeProvider theme={theme}>
      {loading ? (
        <div className={classes.loadingsection}>
          <div className={classes.loadspinner}></div>
        </div>
      ) : null}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className={classes.AppLogo_View}>
            <img
              alt=""
              className={classes.AppLogo}
              src={require("../assets/logoEmpowch.png")}
            />
          </div>
          <Box className={classes.loginbox} Validate sx={{ mt: 3 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="code"
              label="Code"
              name="code"
              autoFocus
              type="text"
              onChange={(e) => setCode(e.target.value)}
              value={code}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="New Password"
              name="password"
              type="password"
              autoComplete="new-password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="confirmPassword"
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              autoComplete="new-password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 4, mb: 2 }}
              color="primary"
              onClick={resetPassword}
            >
              Reset Password
            </Button>
            {message && <p>{message}</p>}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
