import React, { useState, useEffect } from "react";
import { Avatar } from "@mui/material";

import config from "../config/config";
import classes from "../Style/Header.module.css";

function Header() {
  const [Adminname, setAdminname] = useState("");
  const [Adminrole, setAdminrole] = useState("");
  const [adminavatar, setadminavatar] = useState("");

  const loggedInAdmin = JSON.parse(
    sessionStorage.getItem("loggedInAdmin") || "{}"
  );

  useEffect(() => {
    setAdminname(loggedInAdmin.name + " " + loggedInAdmin.surname);
    setAdminrole(loggedInAdmin.type);
    setadminavatar(
      loggedInAdmin.avatar ? config.DOC_SERVER + loggedInAdmin.avatar : ""
    );
  }, []);

  return (
    <div className={classes.Header}>
      <div className={classes.SearchBar}></div>

      <div className={classes.Admin}>
        <div className={classes.Admin_Name}>
          <span>{Adminname}</span>
          <span className={classes.Role}>
            {Adminrole === "S"
              ? "Super Admin"
              : Adminrole === "A"
              ? "Admin"
              : "User"}
          </span>
        </div>
        <Avatar
          src={adminavatar}
          sx={{ width: 55, height: 55, bgcolor: "#64C4B6" }}
        >
          {stringAvatar(Adminname)}
        </Avatar>
      </div>
    </div>
  );
}

export default Header;

function stringAvatar(name) {
  const firstLetters = name
    .split(" ")
    .map((word) => word[0])
    .join("")
    .toUpperCase();

  return firstLetters;
}
