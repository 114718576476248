import React, { useEffect, useState } from "react";
import classes from "../../src/Style/ConnectorDetails.module.css";
import { LinearProgress, Button, styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFetch } from "../hooks/useFetch";
import Wrapper from "../Components/Wrapper";

export default function ConnectorDetails({ google }) {
  const { Fetch } = useFetch();
  const navigate = useNavigate();
  const [Latitude, setLatitude] = useState(0);
  const [Longtitude, setLongtitude] = useState(0);
  const [params] = useSearchParams();
  const [data, setData] = useState({});
  const [loading, setloading] = useState(false);
  const [document, setdocument] = useState("");

  const redirectToGoogleMaps = () => {
    const url = `https://www.google.com/maps/search/?api=1&query=${Latitude},${Longtitude}`;
    window.open(url, "_blank");
  };
  useEffect(() => {
    const getData = async () => {
      setloading(true);
      let res = await Fetch("GET", `tellers?id=${params.get("id")}`);
      console.log(res);

      let doc = await Fetch("post", "users/documents", {
        document: res.tellers[0].document.url || undefined,
      });
      setdocument(doc.document || "");
      res.tellers.forEach((e) => {
        setData(e);
        setLatitude(e.latitude);
        setLongtitude(e.longitude);
      });
      setloading(false);
    };
    getData();
  }, []);
  async function Action(status) {
    let body = {
      tellerId: data.id,
      status: status,
    };
    try {
      let res = await Fetch("post", `tellers/status`, body);
      window.location.reload();
    } catch (error) {
      console.error("Error occurred:", error);
    }
  }
  return (
    <Wrapper bodyFullHeight>
      <section className={classes.BodySection}>
        <div className={classes.DataTableHeader}>
          <h2 className={classes.comptitile}>Connectors Details</h2>

          <div className={classes.DropdownFilters}>
            {data.status == "P" ? (
              <>
                <VerifyButton
                  size="small"
                  variant="contained"
                  onClick={() => {
                    Action("A");
                  }}
                >
                  Verify
                </VerifyButton>
                <RejectButton
                  size="small"
                  variant="contained"
                  onClick={() => {
                    Action("R");
                  }}
                >
                  Reject
                </RejectButton>
              </>
            ) : data.status == "A" ? (
              <>
                <DisableButton
                  size="small"
                  variant="contained"
                  onClick={() => {
                    Action("D");
                  }}
                >
                  Disable
                </DisableButton>

                <InactivateButton
                  size="small"
                  variant="contained"
                  className={classes.btn}
                  color="error"
                  onClick={() => {
                    Action("I");
                  }}
                >
                  Inactivate
                </InactivateButton>
              </>
            ) : data.status == "I" ? (
              <>
                <DisableButton
                  size="small"
                  variant="contained"
                  onClick={() => {
                    Action("D");
                  }}
                >
                  Disable
                </DisableButton>
                <ActivateButton
                  size="small"
                  variant="contained"
                  onClick={() => {
                    Action("A");
                  }}
                >
                  Activate
                </ActivateButton>
              </>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className={classes.bodyContainer}>
          <div className={classes.messageDetailsContainer}>
            <div className={classes.messageRow}>
              <div className={classes.rowTitle}>Bunisess Name</div>
              <div className={classes.rowBody}>{data.name || "-----"}</div>
            </div>

            <div className={classes.messageRow}>
              <div className={classes.rowTitle}>ID</div>
              <div className={classes.rowBody}>{data.id || "-----"}</div>
            </div>
            <div className={classes.messageRow}>
              <div className={classes.rowTitle}>Type</div>
              <div className={classes.rowBody}>{data.type || "-----"}</div>
            </div>
            <div className={classes.messageRow}>
              <div className={classes.rowTitle}>Rating</div>
              <div className={classes.rowBody}>{data.ratings || "0"} *</div>
            </div>
            <div className={classes.messageRow}>
              <div className={classes.rowTitle}>Fees</div>
              <div className={classes.rowBody}>{data.service_fee || "0"} $</div>
            </div>
            <div className={classes.messageRow}>
              <div className={classes.rowTitle}>Status</div>
              <div className={classes.rowBody}>
                {data.status == "P"
                  ? "Pending"
                  : data.status == "A"
                  ? "Active"
                  : "Inactive" || "-----"}
              </div>
            </div>
            <div className={classes.messageRow}>
              <div className={classes.rowTitle}>Location</div>
              <div className={classes.rowBody}>
                <button onClick={redirectToGoogleMaps}>Open Map</button>
              </div>
            </div>
            <div className={classes.messageRow}>
              <div className={classes.rowTitle}>Document</div>
              <div className={classes.rowBody}>
                <button
                  onClick={() => {
                    window.open(document);
                  }}
                >
                  Show Docs
                </button>
              </div>
            </div>
          </div>

          <div className={classes.DataTable}>
            <DataGrid
              sx={{ "& .MuiDataGrid-row:hover": { cursor: "pointer" } }}
              editMode="row"
              onRowClick={({ row }) =>
                navigate(`/Details?id=${row.receiver_id}`)
              }
              columns={headers}
              loading={loading}
              components={{ LoadingOverlay: LinearProgress }}
              rows={data.transactions}
              pageSize={15}
              rowsPerPageOptions={[15]}
            />
          </div>
        </div>
      </section>
    </Wrapper>
  );
}

const headers = [
  {
    flex: 0.4,
    field: "id",
    headerName: "ID",
  },
  {
    flex: 0.4,
    field: "transaction_type",
    headerName: "Receiver",
    renderCell: (row) =>
      row.value == "A" ? row.row.receiver_id : row.row.sender_id,
  },
  {
    flex: 0.8,
    field: "amount",
    headerName: "Amount",
    renderCell: (row) =>
      row.value
        ? `${row.value?.toFixed(2)} ${row.row.receivedCurrency?.name || "USD"}`
        : "TBD",
  },
  {
    field: "received_amount",
    flex: 0.8,
    headerName: "Received Amount",
    renderCell: (row) =>
      row.value
        ? `${row.value?.toFixed(2)} ${row.row.sentCurrency?.name || "USD"}`
        : "TBD",
  },
  {
    field: "fee_in_usd",
    flex: 0.8,
    headerName: "Fees",
    renderCell: (row) => row.value + " USD",
  },
  {
    field: "status",
    flex: 0.5,
    headerName: "Status",
    renderCell: (row) =>
      row.value === "S" ? (
        <span style={{ color: "#0b3758" }}>Complete</span>
      ) : row.value === "P" ? (
        <span style={{ color: "#64c4b6" }}>Pending</span>
      ) : row.value === "R" ? (
        <span style={{ color: "#60100B" }}>Rejected</span>
      ) : row.value === "C" ? (
        <span style={{ color: "#747474" }}>Canceled</span>
      ) : row.value === "F" ? (
        <span style={{ color: "red" }}>Failed</span>
      ) : (
        ""
      ),
  },
];

const ActivateButton = styled(Button)({
  color: "#313131",
  fontSize: 12,
  padding: "6px 12px",
  border: "1px solid #fff",
  boxSizing: "border-box",
  borderRadius: 10,
  lineHeight: 1.5,
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
    border: "1px solid #fff",
  },
});

const InactivateButton = styled(Button)({
  color: "#fff",
  fontSize: 12,
  borderRadius: 10,
  lineHeight: 1.5,
  border: "1px solid #orange",

  backgroundColor: "orange",
  "&:hover": {
    backgroundColor: "orange",
    border: "1px solid orange",
  },
  padding: "6px 12px",
});

const VerifyButton = styled(Button)({
  color: "#fff",
  boxSizing: "border-box",

  fontSize: 12,
  borderRadius: 10,
  padding: "6px 12px",
  lineHeight: 1.5,
  backgroundColor: "#0b3758",
  "&:hover": {
    backgroundColor: "#0b3758",
    border: "1px solid #0b3758",
  },
  border: "1px solid #0b3758",
});
const RejectButton = styled(Button)({
  color: "#fff",
  boxSizing: "border-box",

  fontSize: 12,
  borderRadius: 10,
  padding: "6px 12px",
  lineHeight: 1.5,
  backgroundColor: "#64c4b6",
  "&:hover": {
    backgroundColor: "#64c4b6",
    border: "1px solid #64c4b6",
  },
  border: "1px solid #64c4b6",
});
const DisableButton = styled(Button)({
  color: "#fff",
  boxSizing: "border-box",

  fontSize: 12,
  borderRadius: 10,
  padding: "6px 12px",
  lineHeight: 1.5,
  backgroundColor: "red",
  "&:hover": {
    backgroundColor: "red",
    border: "1px solid red",
  },
  border: "1px solid red",
});
