import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Button,
} from "@mui/material";
import { useFetch } from "../hooks/useFetch";
import LanguageDetect from "languagedetect";

const langdetect = new LanguageDetect();

export default function Dialogue(params) {
  const { Fetch } = useFetch();

  const [message, setmessage] = useState([]);
  const [subject, setsubject] = useState(undefined);

  function detectTextDirection(text) {
    const detectedLanguage = langdetect.detect(text, 1);
    return detectedLanguage.length && detectedLanguage[0][0] === "arabic"
      ? "rtl"
      : "ltr";
  }

  return (
    <div>
      <Dialog
        open={params.open}
        onClose={close_message_dialog}
        fullWidth={useState("md")}
      >
        {/* <DialogTitle>Send SMS</DialogTitle> */}
        <DialogContent>
          <DialogContentText>Send a message to this user</DialogContentText>
          <TextField
            placeholder="Subject"
            autoFocus
            margin="dense"
            label="Subject"
            id="subject"
            onChange={(e) => setsubject(e.target.value)}
            value={subject}
            type="text"
            fullWidth={useState("md")}
            variant="standard"
            style={{ display: params.type == "email" ? "flex" : "none" }}
            dir={detectTextDirection(subject)}
          />
          <TextField
            autoFocus
            margin="dense"
            placeholder="Message"
            label="Message"
            id="message"
            onChange={(e) => {
              setmessage(e.target.value);
            }}
            value={message}
            type="text"
            fullWidth={useState("md")}
            variant="standard"
            multiline
            rows={4}
            maxRows={Infinity}
            dir={detectTextDirection(message)}
          />
          <DialogContentText sx={{ marginTop: 1 }}>
            Recipients
          </DialogContentText>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              maxHeight: "150px",
              overflowY: "scroll",
              justifyContent: "space-between",
              marginTop: "5px",
            }}
          >
            {params.names.length > 0 ? params?.names?.map((item, index) => (
              <span
                key={index}
                style={{
                  backgroundColor: "#eae8e4",
                  width: "120px",
                  borderRadius: "15px",
                  marginRight: "5px",
                  marginBottom: "5px",
                  overflow: "hidden",
                  display: "inline-block",
                  maxHeight: "20px",
                  lineHeight: "20px",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  position: "relative",
                  paddingLeft: "5px",
                }}
                title={item}
              >
                {item}
              </span>
            )) : <span>Sending to all recipients that match your filter criteria</span>}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={close_message_dialog}>Close</Button>
          <Button onClick={send}>Send</Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  function close_message_dialog() {
    params.onClose();
  }

  async function send() {
    await Fetch("post", "users/message", {
      id: params.id,
      message: message,
      type: params.type,
      subject: subject ? subject : "",
      userType: params.userType ? params.userType : "user",
      ...params.filters,
    });

    close_message_dialog();
    setmessage("");
  }
}
