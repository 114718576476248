import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import React from "react";

import { SidebarData } from "../data/SidebarData";
import config from "../config/config";
import classes from "../Style/Sidebar.module.css";

function Sidebar() {
  const navigate = useNavigate();
  function logout() {
    sessionStorage.clear();
    navigate("/Login");
  }

  return (
    <div className={classes.Sidebar}>
      <div className={classes.AppLogo_View}>
        <div
          className={classes.SideBarLogo}
          style={{
            backgroundImage: `url(${require("../assets/logoEmpowch.png")})`,
          }}
        />
      </div>
      <ul className={classes.SidebarList}>
        <div className={classes.SidebarListContainer}>
          {SidebarData.map((val, key) => {
            return (
              <li
                key={key}
                onClick={() => navigate(val.link)}
                className={classes.row}
                id={
                  window.location.pathname === config.BASENAME + val.link
                    ? classes.Active
                    : ""
                }
              >
                <div className={classes.icon}>{val.icon}</div>
                <div className={classes.title}>{val.title}</div>
              </li>
            );
          })}
        </div>
        <div className={classes.footerContainer}>
          <li className={classes.LogOut} onClick={logout}>
            <div className={classes.LogOut_Icon}>
              <LogoutIcon></LogoutIcon>
            </div>
            <div className={classes.LogOut_Text}>Log out</div>
          </li>
          <span style={{ alignSelf: "center", fontSize: 10, color: "grey" }}>
            V2.9.1
          </span>
        </div>
      </ul>
    </div>
  );
}

export default Sidebar;
