import React, { useEffect, useState } from "react";
import classes from "../../src/Style/TicketDetails.module.css";
import {
  Grid,
  Box,
  Typography,
  Paper,
  Button,
  Stack,
  styled,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import HistoryIcon from "@mui/icons-material/History";
import { useFetch } from "../hooks/useFetch";
import Wrapper from "../Components/Wrapper";

export default function TicketDetails() {
  const { Fetch } = useFetch();

  const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    maxWidth: 700,
    color: theme.palette.text.primary,
  }));

  const [loading, setLoading] = useState(false);
  const [ticket_id, setticket_id] = useState("");
  const [id, setid] = useSearchParams();
  const [t_id, sett_id] = useState("");
  const [name, setname] = useState("");
  const [userid, setuserid] = useState("");
  const [email, setemail] = useState("");
  const [type, settype] = useState("");
  const [title, settitle] = useState("");
  const [priority, setpriority] = useState("");
  const [status, setstatus] = useState("");
  const [description, setdescription] = useState("");
  const [history, sethistory] = useState([]);
  const [creationdate, setcreationdate] = useState("");
  const [message, setmessage] = useState([]);
  const [openDialog, setopenDialog] = useState(false);
  const [module, setmodule] = useState("");
  const [changedpriority, setchangedpriority] = useState("0");
  const [reply, setreply] = useState("");

  function changepriority(e) {
    action(null, e.target.value);
  }

  async function Load_data() {
    setLoading(true)
    let res = await Fetch("get", `requests/${id.get("id")}`);
    res = res.rows[0]
    setLoading(false)

    setticket_id(res.ticket_id);
    setname(res.user.name + " " + res.user.surname);
    setuserid(res.user.id);
    setemail(res.email);
    settype(res.type);
    setpriority(res.priority);
    setstatus(res.status);
    setdescription(res.description);
    settitle(res.title);
    sethistory(res.history.split("#^"));
    setcreationdate(res.createdAt);
    sett_id(res.id);
    setmodule(res.module);
    setreply(res.reply);
  }

  useEffect(() => {
    Load_data();
  }, []);

  return (
    <Wrapper loading={loading} bodyFullHeight>
      <div>
        <Dialog
          open={openDialog}
          onClose={close_message_dialog}
          fullWidth={useState("md")}
        >
          <DialogTitle>Send Email to {name}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Send an e-mail message to this user
            </DialogContentText>
            <TextField
              multiline={true}
              rows={5}
              autoFocus
              margin="dense"
              label=""
              id="outlined-multiline-static"
              onChange={(e) => setmessage(e.target.value)}
              value={message}
              type="text"
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={close_message_dialog}>Close</Button>
            <Button onClick={send_message}>Send</Button>
          </DialogActions>
        </Dialog>
      </div>
      <section className={classes.BodySectiond}>
        <div className={classes.DataTableHeader}>
          <h2>Ticket #: {ticket_id} Details</h2>
          <div className={classes.ButtonsSections}>
            <Stack direction="row" spacing={1}>
              <SendMessageButton
                size="small"
                variant="contained"
                onClick={send_message_button}
              >
                {reply ? "Send E-mail" : "Reply"}
              </SendMessageButton>
              {status == "O" || status == "C" ? (
                <SetUnderReview
                  size="small"
                  variant="contained"
                  onClick={() => {
                    action("U", null);
                  }}
                >
                  Set Under Review
                </SetUnderReview>
              ) : (
                <CloseTicket
                  size="small"
                  variant="contained"
                  onClick={() => {
                    action("C", null);
                  }}
                >
                  Close Ticket
                </CloseTicket>
              )}
            </Stack>
          </div>
        </div>

        <div className={classes.DataSection}>
          <div className={classes.TicketData}>
            <div className={classes.UserDetails_Data}>
              <div className={classes.UserDetails_Data_Row}>
                <span className={classes.TicketDetails_Data_Title}>
                  User Name
                </span>
                <span className={classes.TicketDetails_Data_Result}>
                  {name}
                </span>
              </div>
              <div className={classes.UserDetails_Data_Row}>
                <span className={classes.TicketDetails_Data_Title}>
                  User ID
                </span>
                <span className={classes.TicketDetails_Data_Result}>
                  {userid}
                </span>
              </div>
              <div className={classes.UserDetails_Data_Row}>
                <span className={classes.TicketDetails_Data_Title}>E-mail</span>
                <span className={classes.TicketDetails_Data_Result}>
                  {email}
                </span>
              </div>
              <div className={classes.UserDetails_Data_Row}>
                <span className={classes.TicketDetails_Data_Title}>Title</span>
                <span className={classes.TicketDetails_Data_Result}>
                  {title}
                </span>
              </div>
              <div className={classes.UserDetails_Data_Row}>
                <span className={classes.TicketDetails_Data_Title}>Module</span>
                <span className={classes.TicketDetails_Data_Result}>
                  {module}
                </span>
              </div>
              <div className={classes.UserDetails_Data_Row}>
                <span className={classes.TicketDetails_Data_Title}>Type</span>
                <span className={classes.TicketDetails_Data_Result}>
                  {type == "I"
                    ? "Issue"
                    : type == "Q"
                    ? "Question"
                    : type == "S"
                    ? "Suggestion"
                    : ""}
                </span>
              </div>
              <div className={classes.UserDetails_Data_Row}>
                <span className={classes.TicketDetails_Data_Title}>
                  Ticket date
                </span>
                <span className={classes.TicketDetails_Data_Result}>
                  {creationdate}
                </span>
              </div>
              <div className={classes.UserDetails_Data_Row}>
                <span className={classes.TicketDetails_Data_Title}>
                  Priority
                </span>
                <div></div>
                <select
                  className={classes.Dropdown}
                  value={changedpriority}
                  onChange={changepriority}
                >
                  <option value="0">{priority}</option>
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                  <option value="Low">Low</option>
                </select>
              </div>
              <div className={classes.UserDetails_Data_Row}>
                <span className={classes.TicketDetails_Data_Title}>Status</span>
                <span className={classes.TicketDetails_Data_Result}>
                  {status == "U"
                    ? "Under Review"
                    : status == "C"
                    ? "Closed"
                    : "Open"}
                </span>
              </div>
              {/* </div> */}
            </div>
          </div>

          <div className={classes.TicketHistorySection}>
            <div className={classes.scrollhistory}>
              <Box sx={{ flexGrow: 1, px: 3 }}>
                <StyledPaper sx={{ my: 1, mx: 0, p: 2 }}>
                  <Grid container wrap="wrap" spacing={2}>
                    <Grid item xs zeroMinWidth>
                      <Typography wrap="wrap">{description}</Typography>
                    </Grid>
                  </Grid>
                </StyledPaper>

                {history.map((i, index) => {
                  return (
                    <Grid key={index} container wrap="nowrap" spacing={2}>
                      <Grid item xs zeroMinWidth className={classes.grid}>
                        <HistoryIcon />
                        <Typography
                          wrap="wrap"
                          lineHeight={"45px"}
                          className={classes.historytext}
                        >
                          {i}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}

                {reply ? (
                  <StyledPaper sx={{ my: 1, mx: 0, p: 2 }}>
                    <Grid container wrap="wrap" spacing={2}>
                      <Grid item xs zeroMinWidth>
                        <Typography wrap="wrap">{reply}</Typography>
                      </Grid>
                    </Grid>
                  </StyledPaper>
                ) : (
                  <div></div>
                )}
              </Box>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );

  function send_message_button() {
    setopenDialog(true);
  }

  function close_message_dialog() {
    setopenDialog(false);
  }

  async function send_message() {
    await Fetch("post", "requests_action", {
      priority: priority,
      status: status,
      reply: message,
      requestId: t_id,
    });

    close_message_dialog();
    setmessage("");
  }

  async function action(status, priority) {
    await Fetch("post", "requests_action", {
      priority: priority,
      status: status,
      requestId: t_id,
    });

    Load_data();
  }
}

const SendMessageButton = styled(Button)({
  color: "#313131",
  fontSize: 12,
  padding: "6px 12px",
  border: "1px solid #fff",
  boxSizing: "border-box",
  borderRadius: 10,
  lineHeight: 1.5,
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
    border: "1px solid #fff",
  },
});

const CloseTicket = styled(Button)({
  color: "#fff",
  fontSize: 12,
  borderRadius: 10,
  lineHeight: 1.5,
  backgroundColor: "grey",
  "&:hover": {
    backgroundColor: "grey",
  },
});

const SetUnderReview = styled(Button)({
  color: "#fff",
  fontSize: 12,
  borderRadius: 10,
  lineHeight: 1.5,
  backgroundColor: "orange",
  "&:hover": {
    backgroundColor: "orange",
  },
});
