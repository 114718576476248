import React, { useEffect, useState, useRef } from "react";
import classes from "../../src/Style/Transactions.module.css";
import { LinearProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useFetch } from "../hooks/useFetch";
import { generateQueryString } from "../tools/utils";
import Wrapper from "../Components/Wrapper";
import { ExportButton } from "../Components/ExportButton";

function Transactions() {
  const { Fetch } = useFetch();

  const [data, setData] = useState([]); //Transaction Data
  const [loading, setloading] = useState(false);
  const [date, setdate] = useState(undefined);
  const [amount, setamount] = useState(undefined);
  const [status, setstatus] = useState(undefined);
  const [card, setcard] = useState(false);
  const [name, setname] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const [email, setemail] = useState("");
  const [id, setid] = useState("");
  const [bankname, setbankname] = useState("");
  const [plaidnumber, setplaidnumber] = useState("");
  const [bank_accountype, setbank_accounttype] = useState("");
  const [bank_accountname, setbank_accountname] = useState("");
  const [bankcard, setbankcard] = useState(false);
  const [x, setx] = useState(0);
  const [y, sety] = useState(0);
  const [page, setPage] = useState(0);
  const [transactionsCount, setCount] = useState(0);
  const [sort, setSort] = useState(undefined);
  const [currency, setCurrency] = useState(undefined);
  const [currencies, setCurrencies] = useState([]);

  let pageTimer = useRef(null);
  function showCoords(event) {
    setx(event.pageX - 350);
    sety(event.pageY - 220);
  }

  function filterdate(e) {
    setdate(e.target.value !== "0" ? e.target.value : undefined);
  }
  function filteramount(e) {
    setamount(e.target.value !== "0" ? e.target.value : undefined);
  }
  function filterstatus(e) {
    setstatus(e.target.value !== "0" ? e.target.value : undefined);
  }
  function filtercurrency(e) {
    setCurrency(e.target.value !== "0" ? e.target.value : undefined);
  }

  useEffect(() => {
    setloading(true);
    const getData = async () => {
      let filters = {
        page: page,
        status: status,
        date: date,
        amount: amount,
        sortBy: sort,
        currency: currency,
      };
      const queryString = generateQueryString(
        JSON.parse(JSON.stringify(filters))
      );

      let res = await Fetch("get", `transactions?${queryString}`);
      setCurrencies(res.currencies);
      setData(res.data);
      setCount(res.totalCount);

      setloading(false);
    };
    getData();
  }, [date, amount, status, page, sort, currency]);

  return (
    <Wrapper bodyFullHeight>
      <section className={classes.BodySection}>
        <div
          className={classes.usercard}
          style={{
            display: card ? "flex" : "none",
            top: y,
            left: x,
          }}
        >
          <div className={classes.UserDetails_Data_Row}>
            <span className={classes.UserDetails_Data_Title}>Name</span>
            <span className={classes.UserDetails_Data_Result}>{name}</span>
          </div>
          <div className={classes.UserDetails_Data_Row}>
            <span className={classes.UserDetails_Data_Title}>ID</span>
            <span className={classes.UserDetails_Data_Result}>{id}</span>
          </div>
          <div className={classes.UserDetails_Data_Row}>
            <span className={classes.UserDetails_Data_Title}>Phone Number</span>
            <span className={classes.UserDetails_Data_Result}>
              {phonenumber}
            </span>
          </div>
          <div className={classes.UserDetails_Data_Row}>
            <span className={classes.UserDetails_Data_Title}>E-mail</span>
            <span className={classes.UserDetails_Data_Result}>{email}</span>
          </div>
        </div>
        {/*  */}
        <div
          className={classes.usercard}
          style={{
            display: bankcard ? "flex" : "none",
            top: y,
            left: x,
          }}
        >
          <div className={classes.UserDetails_Data_Row}>
            <span className={classes.UserDetails_Data_Title}>Bank Name</span>
            <span className={classes.UserDetails_Data_Result}>{bankname}</span>
          </div>
          <div className={classes.UserDetails_Data_Row}>
            <span className={classes.UserDetails_Data_Title}>Plaid Number</span>
            <span className={classes.UserDetails_Data_Result}>
              **********{plaidnumber}
            </span>
          </div>
          <div className={classes.UserDetails_Data_Row}>
            <span className={classes.UserDetails_Data_Title}>Account Name</span>
            <span className={classes.UserDetails_Data_Result}>
              {bank_accountname}
            </span>
          </div>
          <div className={classes.UserDetails_Data_Row}>
            <span className={classes.UserDetails_Data_Title}>Account Type</span>
            <span className={classes.UserDetails_Data_Result}>
              {bank_accountype}
            </span>
          </div>
        </div>
        {/*  */}
        <div className={classes.DataTableHeader}>
          <h2 className={classes.comptitile}>Transactions</h2>
          <div className={classes.DropdownFilters}>
            <select
              className={classes.Dropdown}
              value={date}
              onChange={filterdate}
            >
              <option value="0">Any Time</option>
              <option value="today">Today</option>
              <option value="lastweek">Last Week</option>
              <option value="lastmonth">Last Month</option>
              <option value="lastyear">Last Year</option>
            </select>
            <select
              className={classes.Dropdown}
              value={amount}
              onChange={filteramount}
            >
              <option value="0">Amount</option>
              <option value="a"> Less than 100$</option>
              <option value="b">Between 100$ - 500$</option>
              <option value="c">More than 500$</option>
            </select>
            <select
              className={classes.Dropdown}
              value={status}
              onChange={filterstatus}
            >
              <option value="0">Status</option>
              <option value="success">Success </option>
              <option value="failed">Failed </option>
              <option value="rejected">Rejected</option>
              <option value="cancelled">Canceled</option>
              <option value="pending">Pending</option>
            </select>
            <select
              className={classes.Dropdown}
              value={currency}
              onChange={filtercurrency}
            >
              <option value="0">Currency</option>
              {currencies.map((i, index) => (
                <option key={index} value={i}>
                  {i === "NATIVE" ? "XLM" : i}
                </option>
              ))}
            </select>
          </div>
        </div>
        <ExportButton table={"transactions"}></ExportButton>
        <div className={classes.DataTable}>
          <DataGrid
            columns={[
              { field: "id", flex: 0.1, headerName: "ID" },
              {
                field: "createdAt",
                flex: 0.1,
                headerName: "Date",
                renderCell: (row) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span>{row.value.split(",")[0]}</span>
                    <span>{row.value.split(",")[1]}</span>
                  </div>
                ),
              },
              {
                field: "amount",
                flex: 0.1,
                headerName: "Sent Amount",
                renderCell: (row) =>
                  row.value
                    ? `${row.value?.toFixed(2)} ${
                        row.row.sentCurrency?.name || "USD"
                      }`
                    : "TBD",
              },
              {
                field: "received_amount",
                flex: 0.1,
                headerName: "Received Amount",
                renderCell: (row) =>
                  row.value
                    ? `${row.value?.toFixed(2)} ${
                        row.row.receivedCurrency?.name || "USD"
                      }`
                    : "TBD",
              },
              {
                field: "request_amount",
                flex: 0.1,
                headerName: "Requested Amount",
                renderCell: (row) =>
                  row.value
                    ? `${row.value?.toFixed(2) || null} ${
                        row.row.receivedCurrency?.name || "USD"
                      }`
                    : "null",
              },
              {
                field: "fee_in_usd",
                flex: 0.1,
                headerName: "Fees",
                renderCell: (row) =>
                  row.value
                    ? `${row.value?.toFixed(5) || 0} ${"USD"}`
                    : "0 USD",
              },
              {
                field: "country_code",
                flex: 0.1,
                sortable: false,
                headerName: "Type",
                renderCell: (row) =>
                  row.row.receiver &&
                  row.row.sender &&
                  row.row.receiver.country_code.toUpperCase() !=
                    row.row.sender.country_code.toUpperCase() ? (
                    <span>Cross-Border</span>
                  ) : (
                    <span>Domestic</span>
                  ),
              },
              {
                field: "type",
                flex: 0.1,
                sortable: false,
                headerName: "Transaction Type",
                renderCell: (row) => (
                  <span>
                    {row.value === "C"
                      ? "Peer To Peer"
                      : row.value === "B"
                      ? "Bank Transfer"
                      : row.value === "Q"
                      ? "Qr Code Payment"
                      : row.value === "S"
                      ? "Swap"
                      : row.value === "D"
                      ? "Card"
                      : row.value === "E"
                      ? "Exchange"
                      : row.value === "P"
                      ? "Connector"
                      : ""}
                  </span>
                ),
              },
              {
                field: "sender_id",
                flex: 0.05,
                headerName: "From",
                renderCell: (row) => (
                  <>
                    {row.row.transaction_type === "A" &&
                    row.row.receiver_id == null ? (
                      <span
                        className={classes.from_to_span}
                        style={{ color: "#0b3758" }}
                        // onMouseMove={showCoords}
                        // onMouseEnter={() => {
                        //   setbankcard(true);
                        //   setbankname(
                        //     row.row.bank
                        //       ? row.row.bank.metadata.institution.name
                        //       : ""
                        //   );
                        //   setplaidnumber(
                        //     row.row.bank
                        //       ? row.row.bank.metadata.accounts[0].meta.number
                        //       : ""
                        //   );
                        //   setbank_accountname(
                        //     row.row.bank
                        //       ? row.row.bank.metadata.accounts[0].meta.name
                        //       : ""
                        //   );
                        //   setbank_accounttype(
                        //     row.row.bank
                        //       ? row.row.bank.metadata.accounts[0].subtype
                        //       : ""
                        //   );
                        // }}
                        // onMouseLeave={() => {
                        //   setbankcard(false);
                        // }}
                      >
                        {row.row.type === "E" ? "External" : "Bank"}
                      </span>
                    ) : (
                      <span
                        className={classes.from_to_span}
                        onMouseMove={showCoords}
                        onMouseEnter={() => {
                          setcard(true);
                          setname(
                            row.row.sender.name + " " + row.row.sender.surname
                          );
                          setphonenumber(row.row.sender.phone_no);
                          setemail(row.row.sender.email);
                          setid(row.row.sender_id);
                        }}
                        onMouseLeave={() => {
                          setcard(false);
                        }}
                      >
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "#64c4b6",
                          }}
                          to={`/Details?id=${row.row.sender_id}`}
                        >
                          {row.value}
                        </Link>
                      </span>
                    )}
                  </>
                ),
              },
              {
                field: "receiver_id",
                flex: 0.05,
                headerName: "To",
                renderCell: (row) =>
                  row.row.transaction_type === "A" && row.row.type !== "C" ? (
                    <span
                      className={classes.from_to_span}
                      onMouseMove={showCoords}
                      onMouseEnter={() => {
                        setcard(true);
                        setname(
                          row.row.sender.name + " " + row.row.sender.surname
                        );
                        setphonenumber(row.row.sender.phone_no);
                        setemail(row.row.sender.email);
                        setid(row.row.sender_id);
                      }}
                      onMouseLeave={() => {
                        setcard(false);
                      }}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "#64c4b6" }}
                        to={`/Details?id=${row.row.sender_id}`}
                      >
                        {row.row.sender_id}
                      </Link>
                    </span>
                  ) : row.row.type == "B" ? (
                    <span
                      className={classes.from_to_span}
                      style={{ color: "#0b3758" }}
                      onMouseMove={showCoords}
                      onMouseEnter={() => {
                        setbankcard(true);
                        setbankname(
                          row.row.bank
                            ? row.row.bank.metadata.institution.name
                            : ""
                        );
                        setplaidnumber(
                          row.row.bank
                            ? row.row.bank.metadata.accounts[0].meta.number
                            : ""
                        );
                        setbank_accountname(
                          row.row.bank
                            ? row.row.bank.metadata.accounts[0].meta.name
                            : ""
                        );
                        setbank_accounttype(
                          row.row.bank
                            ? row.row.bank.metadata.accounts[0].subtype
                            : ""
                        );
                      }}
                      onMouseLeave={() => {
                        setbankcard(false);
                      }}
                    >
                      Bank
                    </span>
                  ) : row.row.type == "M" ? (
                    <span className={classes.from_to_span}>MoneyGram</span>
                  ) : row.row.type == "D" ? (
                    <span className={classes.from_to_span}>Card</span>
                  ) : (
                    <span
                      className={classes.from_to_span}
                      onMouseMove={showCoords}
                      onMouseEnter={() => {
                        setcard(true);
                        setname(
                          row.row.receiver.name + " " + row.row.receiver.surname
                        );
                        setphonenumber(row.row.receiver.phone_no);
                        setemail(row.row.receiver.email);
                        setid(row.row.receiver_id);
                      }}
                      onMouseLeave={() => {
                        setcard(false);
                      }}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "#64c4b6" }}
                        to={`/Details?id=${row.row.receiver_id}`}
                      >
                        {row.value}
                      </Link>
                    </span>
                  ),
              },
              {
                field: "status",
                flex: 0.1,
                sortable: false,
                headerName: "Status",
                renderCell: (row) =>
                  row.value === "S" ? (
                    <a
                      rel="noreferrer"
                      href={row.row.moreInfo}
                      target="_blank"
                      style={{ color: "#0b3758", textDecoration: "none" }}
                    >
                      Complete
                    </a>
                  ) : row.value === "P" ? (
                    <a
                      rel="noreferrer"
                      href={row.row.moreInfo}
                      target="_blank"
                      style={{ color: "#ffa700", textDecoration: "none" }}
                    >
                      Pending
                    </a>
                  ) : row.value === "R" ? (
                    <a
                      rel="noreferrer"
                      href={row.row.moreInfo}
                      target="_blank"
                      style={{ color: "#60100B", textDecoration: "none" }}
                    >
                      Rejected
                    </a>
                  ) : row.value === "C" ? (
                    <a
                      rel="noreferrer"
                      href={row.row.moreInfo}
                      target="_blank"
                      style={{ color: "#747474", textDecoration: "none" }}
                    >
                      Canceled
                    </a>
                  ) : row.value === "F" ? (
                    <a
                      rel="noreferrer"
                      href={row.row.moreInfo}
                      target="_blank"
                      style={{ color: "red", textDecoration: "none" }}
                    >
                      Failed
                    </a>
                  ) : (
                    ""
                  ),
              },
            ]}
            rows={data}
            loading={loading}
            rowCount={transactionsCount}
            onSortModelChange={(e) => {
              setSort(e[0]);
            }}
            pageSize={15}
            paginationMode="server"
            sortingMode="server"
            onPageChange={(newPage) => {
              clearTimeout(pageTimer.current);
              setloading(true);
              pageTimer.current = setTimeout(() => {
                setPage(newPage);
              }, 1500);
            }}
            components={{
              LoadingOverlay: LinearProgress,
            }}
          />
        </div>
      </section>
    </Wrapper>
  );
}

export default Transactions;
