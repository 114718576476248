import React, { useEffect, useState, useRef } from "react";
import { useFetch } from "../hooks/useFetch";
import Wrapper from "../Components/Wrapper";
import classes from "../Style/Purchases.module.css";
import { DataGrid } from "@mui/x-data-grid";
import { STATUS } from "../data/status";
import { Select, MenuItem } from "@mui/material";
import { generateQueryString } from "../tools/utils";
import { UserCard } from "../Components/UserCard";
import { Link } from "react-router-dom";

function Purchases() {
  const { Fetch } = useFetch();
  const [purchases, setPurchases] = useState([]);
  const [page, setPage] = useState(0);
  const [status, setStatus] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const [showUserCard, setShowUserCard] = useState(false);
  const [userCardPosition, setUserCardPosition] = useState({ x: 0, y: 0 });
  const [userCardDetails, setUserCardDetails] = useState({});

  let pageTimer = useRef(null);

  useEffect(() => {
    const getPurchases = async () => {
      if (!loading) setLoading(true);
      let query = { page, status };
      const res = await Fetch("get", `purchases?${generateQueryString(query)}`);
      setPurchases(res.data);
      setLoading(false);
    };
    getPurchases();
  }, [page, status]);

  let columns = [
    { field: "reference_no", headerName: "ID", flex: 1 },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      renderCell: (params) => (
        <div>{new Date(params.row.createdAt).toLocaleString()}</div>
      ),
    },
    {
      field: "user_id",
      headerName: "Customer ID",
      flex: 1,
      renderCell: (params) => (
        <div
          onMouseEnter={(event) =>
            handleCustomerIdHover(event, params.row?.user)
          }
          onMouseLeave={() => setShowUserCard(false)}
        >
          <Link
            style={{
              textDecoration: "none",
              color: "#64c4b6",
            }}
            to={`/Details?id=${params.row.user_id}`}
          >
            {params.row.user_id}
          </Link>
        </div>
      ),
    },
    {
      field: "product_name",
      headerName: "Product Name",
      flex: 1,
      renderCell: (params) => {
        return <div>{params.row?.product?.name}</div>;
      },
    },
    {
      field: "buy_amount",
      headerName: "Amount",
      flex: 1,
      renderCell: (params) => (
        <div>
          {params.row?.buy_amount} {params.row?.currency?.name}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        let { name, style } = STATUS[params.row.status];
        return <div style={{ ...style }}>{name}</div>;
      },
    },
  ];

  const handleCustomerIdHover = (event, user) => {
    setShowUserCard(true);
    setUserCardPosition({ x: event.pageX - 350, y: event.pageY - 220 });
    setUserCardDetails(user);
  };

  return (
    <Wrapper bodyFullHeight>
      <UserCard
        display={showUserCard}
        pos={userCardPosition}
        details={userCardDetails}
      />
      <div className={classes.DataTableHeader}>
        <h2>Purchases</h2>
        <div>
          <Select
            renderValue={() => <span>Status</span>}
            displayEmpty={true}
            value={STATUS[status]?.name || ""}
            onChange={(e) => setStatus(e.target?.value || undefined)}
          >
            <MenuItem value="">All</MenuItem>
            {Object.keys(STATUS).map((status) => (
              <MenuItem value={status}>{STATUS[status]?.name}</MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className={classes.DataTable}>
        <DataGrid
          rows={purchases.rows}
          columns={columns}
          rowCount={purchases.count}
          pageSize={15}
          loading={loading}
          paginationMode="server"
          onPageChange={(newPage) => {
            clearTimeout(pageTimer.current);
            setLoading(true);
            pageTimer.current = setTimeout(() => {
              setPage(newPage);
            }, 1500);
          }}
        />
      </div>
    </Wrapper>
  );
}

export default Purchases;
