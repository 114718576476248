import { useState, useEffect } from "react";
import Wrapper from "../Components/Wrapper";
import classes from "../../src/Style/BusinessDetails.module.css";
import { useFetch } from "../hooks/useFetch";
import { useLocation, useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { TextField, InputLabel, Select, MenuItem } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
// import { ExportButton } from "../Components/ExportButton";

function BusinessDetails() {
  const location = useLocation();
  const params = useParams();
  const { Fetch } = useFetch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [pendingvolume, setPendingvolume] = useState(0);
  const [acceptvolume, setAcceptvolume] = useState(0);
  const [rejectvolume, setRejectvolume] = useState(0);
  const [pendingcount, setPendingcount] = useState(0);
  const [acceptcount, setAcceptcount] = useState(0);
  const [rejectcount, setRejectcount] = useState(0);
  const [filters, setFilters] = useState({
    dateFrom: "",
    dateTo: "",
    status: "",
    search: "",
  });
  const row = location || {};
  const id = params.id;
  let query = new URLSearchParams(useLocation().search);
  let businessVer = query.get("businessVer");
  const Load_data = async () => {
    setLoading(true);
    try {
      let url = `business/${id}${row.search}`;
      if (filters.dateFrom && filters.dateTo) {
        url += `&from=${filters.dateFrom}&to=${filters.dateTo}`;
      }
      if (filters.status) {
        url += `&status=${filters.status}`;
      }
      if (filters.search) {
        url += `&search=${filters.search}`;
      }
      let res = await Fetch("get", url);
      // let res = await Fetch("get", `business/${id}${row.search}`);
      if (Array.isArray(res) && res.length > 0) {
        setHeaderData(res[0]);
        console.log(res);
        if (res[0].transactions) {
          setData(res[0].transactions, calculate);
        }
      } else {
        console.error("API response is empty or invalid");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleChangeFilter = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    Load_data();
  }, [filters]);
  useEffect(() => {
    calculate(); // Call calculate whenever data changes
  }, [data]);
  let columns = [
    {
      field: "reference_no",
      flex: businessVer === "2" ? 0.1 : 0.2,
      headerName: "Reference Number",
    },
    {
      field: "createdAt",
      flex: 0.08,
      headerName: "Date",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span>
            {new Date(params.row.createdAt).toLocaleString().split(",")[0]}
          </span>
          <span>
            {new Date(params.row.createdAt).toLocaleString().split(",")[1]}
          </span>
        </div>
      ),
    },
    {
      field: businessVer === "2" ? "wallet_address" : "receiver_id",
      flex: 0.1,
      headerName: businessVer === "2" ? "Wallet Address" : "Receiver ID",
      ...(businessVer !== "2"
        ? {
            renderCell: (row) => (
              <Link
                style={{ textDecoration: "none", color: "#64c4b6" }}
                to={`/Details?id=${row.row.receiver_id}`}
              >
                {row.row.receiver_id}
              </Link>
            ),
          }
        : {
            valueGetter: (row) =>
              row.row.wallet_address ? row.row.wallet_address : "",
          }),
    },
  ];

  columns.push(
    {
      field: businessVer === "2" ? "sent_amount" : "request_amount",
      flex: 0.1,
      headerName: businessVer === "2" ? "Sent Amount" : "Request Amount",
      renderCell: (row) =>
        row.value
          ? `${row.value?.toFixed(2)} ${
              businessVer === "1"
                ? row.row.receivedCurrency?.name || "USDC"
                : row.row.sent_currency_id === 46
                ? "POINTS"
                : "USDC"
            }`
          : "TBD",
    },

    {
      field: "received_amount",
      flex: 0.07,
      headerName: "Received Amount",

      renderCell: (row) =>
        row.value
          ? `${row.value?.toFixed(2)} ${
              businessVer === "1"
                ? row.row.receivedCurrency?.name || "USDc"
                : row.row.received_currency_id === 1
                ? "USDc"
                : "XLM"
            }`
          : "TBD",
    },
    {
      field: "fee",
      flex: 0.05,
      headerName: "Fee",
      renderCell: (row) =>
        row.value ? `${row.value?.toFixed(2)} ${"USDC"}` : "N/A",
    }
  );

  columns.push({
    field: "status",
    flex: 0.1,
    headerName: "status",
    renderCell: (row) => {
      const statusColor = {
        S: "#0b3758",
        P: "#ffa700",
        R: "#60100B",
        C: "#747474",
        F: "red",
      };
      return (
        <span style={{ color: statusColor[row.value], textDecoration: "none" }}>
          {row.value === "S"
            ? "Accepted"
            : row.value === "P"
            ? "Pending"
            : row.value === "R"
            ? "Rejected"
            : row.value === "C"
            ? "Canceled"
            : row.value === "F"
            ? "Failed"
            : row.value === "Q"
            ? "Quoted"
            : ""}
        </span>
      );
    },
  });

  const calculate = () => {
    let pendingAmount = 0,
      acceptedAmount = 0,
      rejectedAmount = 0;

    let acceptedcount = 0,
      pendingcount = 0,
      rejectedcount = 0;

    if (businessVer === "2") {
      data.forEach((transaction) => {
        if (transaction.status === "S") {
          acceptedAmount += transaction.sent_amount;
          acceptedcount++;
        } else if (transaction.status === "P") {
          pendingAmount += transaction.sent_amount;
          pendingcount++;
        } else if (transaction.status === "R") {
          rejectedAmount += transaction.sent_amount;
          rejectedcount++;
        }
      });
    } else {
      data.forEach((transaction) => {
        if (transaction.status === "S") {
          acceptedAmount += transaction.request_amount;
          acceptedcount++;
        } else if (transaction.status === "P") {
          pendingAmount += transaction.request_amount;
          pendingcount++;
        } else if (transaction.status === "R") {
          rejectedAmount += transaction.request_amount;
          rejectedcount++;
        }
      });
    }

    setPendingvolume(pendingAmount);
    setAcceptvolume(acceptedAmount.toFixed(2));
    setRejectvolume(rejectedAmount);
    setAcceptcount(acceptedcount);
    setPendingcount(pendingcount);
    setRejectcount(rejectedcount);
  };
  return (
    <Wrapper loading={loading} bodyFullHeight>
      <h2 className={classes.headerTitle}>Business Details</h2>
      <div className={classes.headerOfInfo}>
        <div class={classes.colored_box}>
          <p>
            <strong>ID:</strong> {headerData.id}
          </p>
          <p>
            <strong>Name:</strong> {headerData.name}
          </p>
          <p>
            <strong>Email:</strong> {headerData.email}
          </p>
          <p>
            <strong>Phone Number:</strong> {headerData.phone_no}
          </p>
        </div>
        <div>
          <div
            class={classes.colored_box}
            style={{ backgroundColor: "#0b3758", color: "white" }}
          >
            <div class={classes.content_box}>
              <strong>Approved : </strong>
              {acceptcount} transactions = {acceptvolume}$
            </div>
          </div>

          <div
            class={classes.colored_box}
            style={{ backgroundColor: "#ffa700", color: "white" }}
          >
            <div class={classes.content_box}>
              <strong>Pending : </strong>
              {pendingcount} transactions = {pendingvolume}$
            </div>
          </div>

          <div
            class={classes.colored_box}
            style={{ backgroundColor: "#60100B", color: "white" }}
          >
            <div class={classes.content_box}>
              <strong>Rejected : </strong>
              {rejectcount} transactions = {rejectvolume}$
            </div>
          </div>
        </div>
      </div>

      <div className={classes.filters_box}>
        <div>
          <TextField
            label="Search by reference number..."
            type="text"
            id="search"
            name="search"
            value={filters.search}
            onChange={handleChangeFilter}
            size="small"
          />
        </div>
        <div className={classes.date_filters}>
          From
          <div>
            <TextField
              size="small"
              type="date"
              id="dateFrom"
              name="dateFrom"
              value={filters.dateFrom}
              onChange={handleChangeFilter}
            />
          </div>
          To
          <div>
            <TextField
              size="small"
              type="date"
              id="dateTo"
              name="dateTo"
              value={filters.dateTo}
              onChange={handleChangeFilter}
            />
          </div>
        </div>

        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="status">Status</InputLabel>
          <Select
            id="status"
            name="status"
            value={filters.status}
            label="Status"
            onChange={handleChangeFilter}
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value={"S"}>Success</MenuItem>
            <MenuItem value={"P"}>Pending</MenuItem>
            <MenuItem value={"R"}>Rejected</MenuItem>
            <MenuItem value={"F"}>Feild</MenuItem>
            {businessVer === "2" && <MenuItem value={"Q"}>Quoted</MenuItem>}
          </Select>
        </FormControl>
      </div>

      <section className={classes.BodySection} loading={loading}>
        {
          // <div
          //   style={{
          //     padding: "0 3rem",
          //   }}
          // >
          //   <ExportButton table={"business"}></ExportButton>
          // </div>
        }
        {data.length > 0 && (
          <div className={classes.DataTableHeader}>
            <div className={classes.DataTable}>
              <DataGrid columns={columns} rows={data} pageSize={15} />
            </div>
          </div>
        )}

        {data.length === 0 && (
          <div className={classes.dataEmpty}>
            <h3>No Transactions found.</h3>
          </div>
        )}
      </section>
    </Wrapper>
  );
}

export default BusinessDetails;
