import React, { useState, useEffect } from "react";
import classes from "../Style/Dashboard.module.css";
import GroupsIcon from "@mui/icons-material/Groups";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TimelineIcon from "@mui/icons-material/Timeline";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import { Chart as Chartjs } from "chart.js/auto";
import { Doughnut, Bar, Pie, Line } from "react-chartjs-2";
import { useFetch } from "../hooks/useFetch";
import Wrapper from "../Components/Wrapper";

function Dashboard() {
  const { Fetch } = useFetch();

  const [Loading, setLoading] = useState(false);

  const [totalRevenue, setTotalRevenue] = useState(0);
  const [usersStats, setUsersStats] = useState(usersStatsSkeleton);
  const [transactionsStats, setTransactionsStats] = useState({
    daily: transactionsStatsSkeleton,
    monthly: transactionsStatsSkeleton,
    yearly: transactionsStatsSkeleton,
  });

  const [lineChartData, setLineChartData] = useState(chartDataSkeleton);
  const [barChartData, setBarChartData] = useState(chartDataSkeleton);
  const [donutChartData, setDonutChartData] = useState({
    daily: chartDataSkeleton,
    monthly: chartDataSkeleton,
    yearly: chartDataSkeleton,
    pending: chartDataSkeleton,
  });

  const [transactionsFilter, setTransactionsFilter] = useState("daily");
  const [chartFilter, setChartFilter] = useState("perdate");

  async function loadData() {
    setLoading(true);

    let [
      dailyNewUsers,
      monthlyNewUsers,
      yearlyNewUsers,
      pendingUsers,

      perMonthCount,
      countsPerCountry,

      dailyTransaction,
      monthlyTransaction,
      yearlyTransaction,
      pendingTransactions,
      totalFees,
    ] = await Promise.all([
      Fetch("get", "report/daily_new_users"),
      Fetch("get", "report/monthly_new_users"),
      Fetch("get", "report/yearly_new_users"),
      Fetch("get", "report/pending_users"),

      Fetch("get", "report/per_month_count"),
      Fetch("get", "report/counts_per_country"),

      Fetch("get", "report/daily_transaction"),
      Fetch("get", "report/monthly_transaction"),
      Fetch("get", "report/yearly_transaction"),
      Fetch("get", "report/pending_transactions"),
      Fetch("get", "report/fees"),
    ]);

    // --- FEES ---
    setTotalRevenue(parseFloat(totalFees.totalFees).toFixed(2));

    // --- USERS STATS ---
    setUsersStats({
      totalCount: perMonthCount.total,
      dailyCount: dailyNewUsers.current,
      monthlyCount: monthlyNewUsers.current,
      yearlyCount: yearlyNewUsers.current,
      pendingUsersCount: pendingUsers.pendingCount,

      dailyGrowth: calcGrowth(dailyNewUsers.current, dailyNewUsers.previous),
      monthlyGrowth: calcGrowth(
        monthlyNewUsers.current,
        monthlyNewUsers.previous
      ),
      yearlyGrowth: calcGrowth(yearlyNewUsers.current, yearlyNewUsers.previous),
    });

    // --- TRANSACTIONS STATS ---
    const transactionData = {
      daily: dailyTransaction,
      monthly: monthlyTransaction,
      yearly: yearlyTransaction,
    };

    setTransactionsStats((transactionsStats) => {
      for (let [key, value] of Object.entries(transactionData)) {
        let average = calcAverage(value[key][0]?.amount, value[key][0]?.count);
        let growth = calcGrowth(value[key][0]?.amount, value[key][1]?.amount);

        transactionsStats[key] = {
          total: value[key][0]?.count,
          volume: value[key][0]?.amount?.toFixed(2) || "0",
          average: isNaN(average) ? "0" : average,
          growth: isNaN(growth) ? "0" : !isFinite(growth) ? "100" : growth,
        };
      }

      return transactionsStats;
    });

    // --- DONUT CHARTS ---
    setDonutChartData((donutChartData) => {
      for (let [key, value] of Object.entries(transactionData)) {
        let crossBorderPercentage =
          (value?.crossBorder?.count * 100) / value[key][0]?.count;
        crossBorderPercentage = isNaN(crossBorderPercentage)
          ? "0"
          : crossBorderPercentage.toFixed(2);

        let domesticPercentage =
          (value?.domestic?.count * 100) / value[key][0]?.count;
        domesticPercentage = isNaN(domesticPercentage)
          ? "0"
          : domesticPercentage.toFixed(2);

        donutChartData[key] = {
          datasets: [
            {
              data: [value?.domestic?.amount, value?.crossBorder?.amount],
              backgroundColor: ["#64c4b6", "#0b3758"],
              hoverOffset: 4,
            },
          ],
          labels: [
            "Domestic: " + domesticPercentage + "%",
            "Cross-border: " + crossBorderPercentage + "%",
          ],
        };
      }

      donutChartData.pending = {
        datasets: [
          {
            data: [
              pendingTransactions.daily?.amount,
              pendingTransactions.monthly?.amount,
              pendingTransactions.yearly?.amount,
            ],
            backgroundColor: ["#FFA500", "#FF8C00", "#FF4500"],
            hoverOffset: 4,
          },
        ],
        labels: [
          "Daily: " + pendingTransactions.daily?.count,
          "Monthly: " + pendingTransactions.monthly?.count,
          "yearly: " + pendingTransactions.yearly?.count,
        ],
      };

      return donutChartData;
    });

    // --- LINE CHART ---
    setLineChartData(() => {
      let users = [];
      let months = [];
      perMonthCount.monthly.forEach((i) => {
        months.push(i.date);
        users.push(i.count);
      });
      return {
        labels: months,
        datasets: [
          {
            label: "Users",
            data: users,
            fill: true,
            borderColor: "#64c4b6",
            backgroundColor: "#64c4b653",
            tension: 0.4,
          },
        ],
      };
    });

    // --- BAR CHART ---
    setBarChartData(() => {
      let users = [];
      let countries = [];
      countsPerCountry.forEach((j) => {
        countries.push(j.country_code.toUpperCase());
        users.push(j.counts);
      });
      return {
        labels: countries,
        datasets: [
          {
            label: "Users",
            data: users,
            fill: true,
            borderColor: "#64c4b6",
            backgroundColor: "#64c4b689",
            tension: 0.4,
          },
        ],
      };
    });

    setLoading(false);
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Wrapper loading={Loading}>
      <section className={classes.BodySection}>
        <div className={classes.DataTableHeader}>
          <h2 className={classes.comptitile}>Dashboard</h2>
        </div>

        <div className={classes.user_numbers_data_section}>
          <div className={classes.total_user_numbers_div}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontSize: 10 }}>Total users</span>
              <span style={{ fontSize: 30 }}>{usersStats.totalCount}</span>
            </div>
            <GroupsIcon fontSize="large" />
          </div>

          <div className={classes.user_numbers_analytics_div}>
            <div className={classes.user_numbers_analytics}>
              <div className={classes.user_numbers_data}>
                <span style={{ fontSize: 8 }}>Daily users</span>
                <span style={{ fontSize: 23 }}>{usersStats.dailyCount}</span>
                <div className={classes.percentage_number}>
                  <span style={{ fontSize: 7 }}>
                    {isNaN(usersStats.dailyGrowth)
                      ? "0"
                      : !isFinite(usersStats.dailyGrowth)
                      ? "100"
                      : usersStats.dailyGrowth}
                    %{" "}
                  </span>
                  {usersStats.dailyGrowth < 0 ? (
                    <ArrowDropDownIcon fontSize="small" htmlColor="red" />
                  ) : (
                    <ArrowDropUpIcon fontSize="small" htmlColor="#64c4b6" />
                  )}
                </div>
              </div>
              <div className={classes.user_numbers_graph}>
                <TimelineIcon
                  fontSize="large"
                  htmlColor={usersStats.dailyGrowth < 0 ? "red" : "#64c4b6"}
                />
              </div>
            </div>

            <div className={classes.user_numbers_analytics}>
              <div className={classes.user_numbers_data}>
                <span style={{ fontSize: 8 }}>Monthly users</span>
                <span style={{ fontSize: 23 }}>{usersStats.monthlyCount}</span>
                <div className={classes.percentage_number}>
                  <span style={{ fontSize: 7 }}>
                    {isNaN(usersStats.monthlyGrowth)
                      ? "0"
                      : !isFinite(usersStats.monthlyGrowth)
                      ? "100"
                      : usersStats.monthlyGrowth}
                    %{" "}
                  </span>
                  {usersStats.monthlyGrowth < 0 ? (
                    <ArrowDropDownIcon fontSize="small" htmlColor="red" />
                  ) : (
                    <ArrowDropUpIcon fontSize="small" htmlColor="#64c4b6" />
                  )}
                </div>
              </div>
              <div className={classes.user_numbers_graph}>
                <TimelineIcon
                  fontSize="large"
                  htmlColor={usersStats.monthlyGrowth < 0 ? "red" : "#64c4b6"}
                />
              </div>
            </div>

            <div className={classes.user_numbers_analytics}>
              <div className={classes.user_numbers_data}>
                <span style={{ fontSize: 8 }}>Yearly users</span>
                <span style={{ fontSize: 23 }}>{usersStats.yearlyCount}</span>
                <div className={classes.percentage_number}>
                  <span style={{ fontSize: 7 }}>
                    {isNaN(usersStats.yearlyGrowth)
                      ? "0"
                      : !isFinite(usersStats.yearlyGrowth)
                      ? "100"
                      : usersStats.yearlyGrowth}
                    %{" "}
                  </span>
                  {usersStats.yearlyGrowth < 0 ? (
                    <ArrowDropDownIcon fontSize="small" htmlColor="red" />
                  ) : (
                    <ArrowDropUpIcon fontSize="small" htmlColor="#64c4b6" />
                  )}
                </div>
              </div>
              <div className={classes.user_numbers_graph}>
                <TimelineIcon
                  fontSize="large"
                  htmlColor={usersStats.yearlyGrowth < 0 ? "red" : "#64c4b6"}
                />
              </div>
            </div>

            <div className={classes.waiting_approval_users}>
              <span style={{ fontSize: 10 }}>Waiting Approval</span>
              <span style={{ fontSize: 28 }}>
                {usersStats.pendingUsersCount}
              </span>
            </div>
          </div>

          <div className={classes.total_revenue_numbers_div}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontSize: 10 }}>Total revenue</span>
              <span style={{ fontSize: 30 }}>${totalRevenue}</span>
            </div>
            <PointOfSaleIcon fontSize="large" />
          </div>
        </div>

        <div className={classes.users_transactions_div}>
          <div className={classes.users_graph_div}>
            <div className={classes.DashboardDropdownFilters}>
              <select
                className={classes.Transactions_Dropdown}
                id="chartFilter"
                value={chartFilter}
                onChange={(e) => setChartFilter(e.target.value)}
              >
                <option value="perdate">Per Date</option>
                <option value="percountry">Per Country</option>
              </select>
            </div>

            <div className={classes.DashboardGraphContainer}>
              {chartFilter == "perdate" ? (
                <Line
                  data={lineChartData}
                  options={{ maintainAspectRatio: false }}
                />
              ) : chartFilter == "percountry" ? (
                <Bar
                  data={barChartData}
                  options={{ maintainAspectRatio: false }}
                />
              ) : (
                ""
              )}
            </div>
          </div>

          <div className={classes.transactions_charts_div}>
            <div className={classes.transactions_charts_title}>
              <span style={{ alignSelf: "center" }}>Transactions</span>
              <div className={classes.DropdownFilters}>
                <select
                  className={classes.Transactions_Dropdown}
                  id="filterdate"
                  value={transactionsFilter}
                  onChange={(e) => setTransactionsFilter(e.target.value)}
                >
                  <option value="daily">Daily</option>
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                </select>
              </div>
            </div>

            <div className={classes.transactions_charts_data_div}>
              <span className={classes.transactions_charts_data_title}>
                Total Transactions
              </span>
              <span className={classes.transactions_charts_data_nbrs}>
                {transactionsFilter == "daily"
                  ? transactionsStats.daily.total
                  : transactionsFilter == "monthly"
                  ? transactionsStats.monthly.total
                  : transactionsFilter == "yearly"
                  ? transactionsStats.yearly.total
                  : "-"}
              </span>
            </div>

            <div className={classes.transactions_charts_data_div}>
              <span className={classes.transactions_charts_data_title}>
                Transactions Volume
              </span>
              <span className={classes.transactions_charts_data_nbrs}>
                {transactionsFilter == "daily"
                  ? transactionsStats.daily.volume
                  : transactionsFilter == "monthly"
                  ? transactionsStats.monthly.volume
                  : transactionsFilter == "yearly"
                  ? transactionsStats.yearly.volume
                  : ""}{" "}
                $
              </span>
            </div>

            <div className={classes.transactions_charts_data_div}>
              <span className={classes.transactions_charts_data_title}>
                Average Transactions
              </span>
              <span className={classes.transactions_charts_data_nbrs}>
                {transactionsFilter == "daily"
                  ? isNaN(transactionsStats.daily.average)
                    ? "0"
                    : transactionsStats.daily.average
                  : transactionsFilter == "monthly"
                  ? isNaN(transactionsStats.monthly.average)
                    ? "0"
                    : transactionsStats.monthly.average
                  : transactionsFilter == "yearly"
                  ? isNaN(transactionsStats.yearly.average)
                    ? "0"
                    : transactionsStats.yearly.average
                  : ""}{" "}
                $
              </span>
            </div>

            <div className={classes.transactions_charts_data_div}>
              <span className={classes.transactions_charts_data_title}>
                D2D Growth
              </span>
              <span className={classes.transactions_charts_data_nbrs}>
                {transactionsFilter == "daily"
                  ? transactionsStats.daily.growth
                  : transactionsFilter == "monthly"
                  ? transactionsStats.monthly.growth
                  : transactionsFilter == "yearly"
                  ? transactionsStats.yearly.growth
                  : ""}{" "}
                %
              </span>
            </div>
          </div>
        </div>

        <div className={classes.transactions_type_div}>
          <div className={classes.transactions_piecharts_div}>
            <span className={classes.transactions_piecharts_title}>
              Daily Transactions
            </span>
            <div className={classes.transactions_piecharts}>
              <Doughnut
                data={donutChartData.daily}
                style={{ height: 180, width: 180 }}
              />
            </div>
          </div>

          <div className={classes.transactions_piecharts_div}>
            <span className={classes.transactions_piecharts_title}>
              Monthly Transactions
            </span>
            <div className={classes.transactions_piecharts}>
              <Doughnut
                data={donutChartData.monthly}
                style={{ height: 180, width: 180 }}
              />
            </div>
          </div>

          <div className={classes.transactions_piecharts_div}>
            <span className={classes.transactions_piecharts_title}>
              Yearly Transactions
            </span>
            <div className={classes.transactions_piecharts}>
              <Doughnut
                data={donutChartData.yearly}
                style={{ height: 180, width: 180 }}
              />
            </div>
          </div>

          <div className={classes.transactions_piecharts_div}>
            <span className={classes.transactions_piecharts_title}>
              Pending Transactions
            </span>
            <div className={classes.transactions_piecharts}>
              <Pie
                data={donutChartData.pending}
                style={{ height: 180, width: 180 }}
              />
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );

  function calcAverage(num1, num2) {
    return (num1 / num2).toFixed(2);
  }

  function calcGrowth(num1, num2) {
    return (((num1 - num2) / num2) * 100).toFixed(2);
  }
}

export default Dashboard;

const usersStatsSkeleton = {
  totalCount: "",
  dailyCount: "",
  monthlyCount: "",
  yearlyCount: "",

  dailyGrowth: "",
  monthlyGrowth: "",
  yearlyGrowth: "",
  pendingUsersCount: "",
};
const transactionsStatsSkeleton = {
  total: "",
  volume: "",
  average: "",
  growth: "",
};
const chartDataSkeleton = { datasets: [], labels: [] };
