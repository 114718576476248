import React from "react";

import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import StoreIcon from '@mui/icons-material/Store';
export const SidebarData = [
  {
    title: "Dashboard",
    icon: <GridViewOutlinedIcon />,
    link: "/Dashboard",
  },
  {
    title: "Master Wallet",
    icon: <AccountBalanceWalletOutlinedIcon />,
    link: "/Wallet",
  },
  {
    title: "Customers",
    icon: <GroupsOutlinedIcon />,
    link: "/Customers",
  },
  {
    title: "Transactions",
    icon: <ReceiptLongOutlinedIcon />,
    link: "/Transactions",
  },
  {
    title: "Purchases",
    icon: <StoreIcon />,
    link: "/Purchases",
  },
  {
    title: "Settings",
    icon: <SettingsOutlinedIcon />,
    link: "/Settings",
  },
  {
    title: "Tickets",
    icon: <ContactMailOutlinedIcon />,
    link: "/Tickets",
  },
  {
    title: "Business Center",
    icon: <BusinessCenterOutlinedIcon />,
    link: "/Business",
  },
  {
    title: "Messages",
    icon: <MailOutlineIcon />,
    link: "/messages",
  },
  {
    title: "Connectors",
    icon: <HubOutlinedIcon />,
    link: "/Connectors",
  },
];
